// ActionsCard.vue
<template>
<div class="actionscard" :class="{ 'opacity-disabled': isDisabled }" >

    <div class="subtitle" :class="!showConf ? 'opacity-class' : 'hidden-opacity'">Maßnahme</div>
            <div class="title" :class="!showConf ? 'opacity-class' : 'small-title'">{{ card.Title }}</div>
           <div :class="showConf ? 'opacity-class' : 'hidden-opacity'">Wollen Sie diese Maßnahme endgültig löschen?</div>
            <div class="card-color" >
                <ActionButton :size="'small'" :action="'delete'" v-on:deleteClick="showConfirmation"  v-if="!this.showConf"></ActionButton>
                <ActionButton :size="'small'" :action="'open'" v-on:openClick="setactiondetails(card)"  v-if="!this.showConf"></ActionButton>
                <ActionButton :size="'small'" :action="'delete'" v-on:deleteClick="deleteAction"  v-if="this.showConf"></ActionButton>
                <ActionButton :size="'small'" :action="'cancel'" v-on:cancelClick="cancelConfirmation"  v-if="this.showConf"></ActionButton>
            </div>
            <div class="bg-img" :style="{ 'background-image': topo ? `url('${topo}')` : 'none', 'background-position': `${this.position}px ${this.position}px` }" :class="showConf ? 'scale-class' : ''"></div>
          
          </div>
        
</template>
<script>
import ActionButton from '../Buttons/ActionButton.vue';

export default {
name: 'ActionsCard',
    props: {
        card: {
            type: Object,
            default() {
                return {}
            }
        },
        position: {
            type: Number,
            default: 0
        },
        isDisabled: { 
      type: Boolean,
      default: false,
    },
        
    },
    data() {
        return {    
            topo: process.env.BASE_URL + 'img/topo.svg',    
        backgroundImage: '',
        showConf: false
        }
    },
    components: {
        ActionButton
    },
    methods: {
        setactiondetails($target, card){
      const clickedCard = $target.parentNode;
  const originalRecord = card;
  this.$emit('setaction', [originalRecord, clickedCard]);
    },
    showConfirmation() {
        this.showConf = true;
        this.$emit('showConfirmation', this.card.Id);  
    },
    deleteAction() {
        this.$store.dispatch("removeEntMki", {measureId: this.card.Id});
    },
    cancelConfirmation() {
      this.showConf = false;
      this.$emit('cancelConfirmation');  // Emit a new event
    },
    },
    mounted() {
    let img = new Image();
    img.onload = () => {
        this.backgroundImage = `url(${img.src})`;
    };
    img.src = this.topo;
},
}
</script>
<style scoped lang="css">
.actionscard {

min-height: 80px;
box-shadow: var(--button-elevation-medium);
display: grid;
flex-grow: .3;
margin-right: 10px;
margin-top: 1%;
padding: 20px;
background-image: linear-gradient(to top,
      #f3e7e9 0%,
      #e3eeff 99%,
      #e3eeff 100%);
  position: relative;
  filter: contrast(1);
  background-image: linear-gradient(to top, #dfe9f326 0%, #ffffffe5 100%);
  margin: 1%;
  backdrop-filter: blur(10px);
font-size: var(--font-size-slightly-larger);

align-content: center;
margin-left: 0;
background-image:  linear-gradient(to top, #dfe9f326 0%, #ffffffe5 100%);
background-blend-mode: multiply;
background-position: center;
overflow: hidden;
}
.subtitle {
    font-size: 80%;
    font-family: 'Aileronsemibold';
    width: 100%;
}

.bg-img {
position: absolute;
  top: 0;
  left: 0;
  width: 1918px;
  height: 1446px;
  z-index: -1;
  mask-image: radial-gradient(hsl(0, 0%, 0%), #fff, #000000ba, #00000082);
  filter: blur(1px);
inset: 0;
transition: transform .3s cubic-bezier(.19,1,.22,1),filter .3s cubic-bezier(.19,1,.22,1);
}
.card-color {
    place-self: end;
display: grid;
grid-auto-flow: column;
grid-gap: 10%;
margin: 14px 0;
padding-right: 20px;
}

 .scale-class {
    transition: transform .3s cubic-bezier(.95,.05,.8,.04),filter .3s cubic-bezier(.95,.05,.8,.04);
transform: scale(2);
filter: blur(10px);
 }
 .hidden-opacity {
    opacity: 0;
    transition: opacity .5s ease;
 }
 .opacity-class {
    opacity: 1;
    transition: opacity .5s ease;
 }
 .opacity-disabled {
  filter: opacity(.5) grayscale(1) blur(5px);
  transition: filter .2s cubic-bezier(.95,.05,.8,.04),  box-shadow .2s cubic-bezier(.95,.05,.8,.04);
  box-shadow: var(--button-elevation-low);
  
  
}
.high-elevation {
    box-shadow: var(--button-elevation-high);
    transition: box-shadow .2s cubic-bezier(.95,.05,.8,.04);

}

.small-title {
    opacity: 1;
font-size: 70%;
text-decoration: underline;
}
</style>