import store from "../index"
import api from '../../api/api.js'
import { waitForColleagues } from '../../utils/dataUtils';

const state = {
  studentId: null,
  zid: null,
  zyklen: null,
  loading: false,
  loadAttempted: false,
  loadFailed: false ,
  currentZyklus: null,

}

const actions = {
  loadZyklenIfNeeded({ state, dispatch }, sid) {
    if (state.zyklen === null && !state.loading && !state.loadAttempted) {
      return dispatch("loadZyklen", sid);
    }
  },
// Aktion zum Laden der Zyklen-Daten
loadZyklen({commit, state}, id) {
  let wasSuccessful = null; // Variable zur Verfolgung des Erfolgsstatus

  // Rückgabe eines Promises, um asynchrone Operationen zu ermöglichen
  return new Promise((resolve, reject) => {

    // Daten nur laden, wenn notwendig (d.h. wenn die ID sich geändert hat oder noch kein Ladeversuch unternommen wurde)
    if(state.studentId !== id || !state.loadAttempted) {

      // Ladeindikator setzen
      commit('setZyklenLoading', true);

      // API-Aufruf zum Laden der Zyklen
      api.loadZyklen(id)
      .then(res => {
        // Erfolgreicher API-Aufruf: Zyklen-Daten im Zustand speichern
        commit('setZyklen', res);
        commit('setZyklenStudentId', id);
        wasSuccessful = true; 
        resolve(res);
      })
      .catch(err => {
        // Fehler beim API-Aufruf: Fehler zurückgeben
        wasSuccessful = false;
        reject(err);
      })
      .finally(() => {
        // Abschluss des API-Aufrufs: Ladeindikator zurücksetzen und Erfolgsstatus speichern
        setTimeout(() => {
          commit('setZyklenLoading', false);
          if (wasSuccessful !== null) {
            commit('setLoadAttempted', true);
          }
          commit('setLoadFailed', !wasSuccessful);
        }, 1000);
      });
    } else {
      // Wenn Daten bereits geladen sind, diese zurückgeben
      if(state.zyklen && Array.isArray(state.zyklen)) resolve(state.zyklen);
    }
  });
},

  addZyklus({commit}, zyklus) {
    return new Promise((resolve, reject) => {
      commit('setZyklenLoading', true)
      api.addZyklus(zyklus)
      .then(zyklus => {
        commit('addZyklus', zyklus)
        resolve(zyklus.Id)
      })
      .catch(err => {
        alert("Es trat ein Problem mit der Serververbindung auf. Es konnte leider kein neuer Zyklus angelegt werden.")
        console.log("Error while add zyklus: " +err)
        reject(err)
      })
      .then(() => {
        commit('setZyklenLoading', false)
      })
    })
  },
  changeZyknPiC({commit}, payload) {
    commit('setZyklenLoading', true)
    api.changeZyknPiC(payload)
    .then(res => {
      commit('ZyknsetPiC', payload, res)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Die Veränderung der Verantwortlichkeiten konnte nicht gespeichert werden")
      console.log("Error while saving datenschutz: " +err)
    })
    .then(() => {
      commit('setZyklenLoading', false)
    })
  },
  deleteCycl({commit}, id) {
    commit('setZyklenLoading', true)
    api.deleteCycl(id)
    .then(() => {
      commit('deleteCycl', id)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Löschen des Zyklus konnte leider nicht verarbeitet werden.")
      console.log("Error while deleting student: " +err)
    })
    .then(() => {
      commit('setZyklenLoading', false)
    })
  },
  saveZyklusEb(_, payload) {
    api.saveZyklusEb(payload)
    .catch(err => {
      console.log("Error while saving eb: " +err)
    })
  },
  saveZyklusAbschluss(_, payload) {
    api.saveZyklusAbschluss(payload)
    .catch(err => {
      console.log("Error while saving abschluss: " +err)
    })
  },
  saveZyklusEval(_, payload) {
    api.saveZyklusEval(payload)
    .catch(err => {
      console.log("Error while saving eval: " +err)
    })
  },
  saveZyklusKlasse(_, payload) {
    api.saveZyklusKlasse(payload)
    .catch(err => {
      console.log("Error while saving klasse: " +err)
    })
  },
  saveZyklusStartDate(_, payload) {
    api.saveZyklusStartDate(payload)
    .catch(err => {
      console.log("Error while saving klasse: " +err)
    })
  },
  saveZyklusEndDate(_, payload) {
    api.saveZyklusEndDate(payload)
    .catch(err => {
      console.log("Error while saving klasse: " +err)
    })
  },

  async updateZyklusVerantwortliche({commit}, payload) {
    commit("setZyklusPiC", payload);
    try {
      await api.updateZyklusVerantwortliche(payload);
      console.log("updateZyklusVerantwortliche called with payload:", payload);
    } catch (err) {
      console.log("Error while adding zyklus verantwortliche: " + err);
    }
  }
}

const getters = {
  zyklen: state => id => {
    // Daten abrufen, wenn sie noch nicht geladen sind oder kein anderer Ladevorgang läuft.
    if (!state.loadAttempted || state.loadFailed || (state.studentId !== id && !state.loading)) {
      if (!state.loading) {
        store.dispatch("loadZyklen", id);
      }
    }
    return state.zyklen;
  },
  

  zyklenStudent: state => id => {
    // Daten abrufen, wenn sie noch nicht geladen sind oder kein anderer Ladevorgang läuft.
    if (!state.loading && 
        (!state.loadAttempted || 
         state.loadFailed || 
         state.studentId !== id || 
         (state.loadAttempted && state.zyklen === null))) {
      store.dispatch("loadZyklen", id);
    }
    // Zyklen nach ID filtern
    if(state.zyklen && Array.isArray(state.zyklen) && state.zyklen.length >= 0 && id) {
      return state.zyklen.filter(item => item.RefPEPId === id);
    } else {
      return null;
    }
  },
  
  
  zyklenLoading: state => {
    return state.loading
  },
 // Vuex Getter
zyklusById: state => payload => {
  // Überprüfung der Payload
  if (!payload.sid && !payload.zid) {
    console.error('Both sid and zid are missing in payload');
    return null;
  }

  // Wenn currentZyklus im Zustand ist und die ID übereinstimmt, verwenden Sie es
  if (state.currentZyklus && state.currentZyklus.Id === payload.zid) {
    return state.currentZyklus;
  }

  // Wenn die Zyklen nicht geladen sind, versuchen Sie, sie zu laden (über eine Aktion)
  if (state.zyklen === null && !state.loading && !state.loadAttempted && payload.sid) {
    store.dispatch("loadZyklenIfNeeded", payload.sid);
  }

  // Zyklus anhand der ID suchen
  if (state.zyklen !== null && Array.isArray(state.zyklen) && payload.zid) {
    const foundZyklus = state.zyklen.find(
      item => item.Id === payload.zid || String(item.Id) === String(payload.zid)
    );

    // Wenn gefunden, aktualisieren Sie currentZyklus im Zustand
    if (foundZyklus) {
      store.commit('setCurrentZyklus', foundZyklus);
    }

    return foundZyklus;
  }

  console.log('Returning null');
  return null;
},

  
  zyklusTitleById: () => payload => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus && zyklus.Title) return zyklus.Title
    else return null
  },
  zyklusEbById: () => payload => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) return zyklus.EB
    else return null
  },
  zyklusAbschlussById: () => payload => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) return zyklus.Abschluss
    else return null
  },
  zyklusEvalById: () => payload => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) return zyklus.Eval
    else return null
  },
  zyklusPiC: () => async payload => {
    console.log("zyklusPiC called with payload:", payload);
    try {
      let colleagues = await waitForColleagues();
      console.log("Colleagues data:", colleagues);
      
      let zyklus = await waitForZyklus(payload);
      console.log("Zyklus data:", zyklus);
  
      
    if (zyklus) {
      let ids = [];
  
      // Prüfen, ob PiCId verfügbar ist und Ergebnisse enthält
      if (zyklus.PiCId && Array.isArray(zyklus.PiCId.results) && zyklus.PiCId.results.length) {
        ids = zyklus.PiCId.results;
      }
      // Prüfen, ob PiC verfügbar ist und Ergebnisse enthält
      else if (zyklus.PiC && Array.isArray(zyklus.PiC.results) && zyklus.PiC.results.length) {
        ids = zyklus.PiC.results.map(p => p.Id);
        console.log("IDs to filter:", ids);
      }
      // Prüfen, ob Author verfügbar ist
      else if (zyklus.Author && zyklus.Author.Title && zyklus.Author.Id) {
        return [{"Title": zyklus.Author.Title, "Id": zyklus.Author.Id}];
      }
  
      // Filtern der Kollegen anhand der IDs
      if (colleagues && ids.length > 0) {
        console.log("IDs to filter:", ids);
        console.log("Colleague IDs:", colleagues.map(c => c.Id));
        return colleagues.filter(colleague => ids.includes(colleague.Id));
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return [];
  }
},
  zyklenPiC: state => {
    let picSet = new Set();
    console.log("State zyklen:", state.zyklen);  // Debugging-Statement
    if (Array.isArray(state.zyklen)) {
      for (let zyklus of state.zyklen) {
        console.log("Zyklus:", zyklus);  // Debugging-Statement
        if (Array.isArray(zyklus.PiCId.results)) {
          for (let id of zyklus.PiCId.results) {
            picSet.add(id);
          }
        }
      }
    }
    console.log("PicSet:", picSet);  // Debugging-Statement
    return Array.from(picSet);
  },
  getZid: state => {
    return state.zid;
  },
  getStudentId: state => {
    return state.studentId;
  }
  
}

const mutations = {
  setZyklenStudentId: (state, studentId) => {
    state.studentId = studentId
  },
  setZid(state, id) {
    if (!isNaN(id)) {
      state.zid = parseInt(id);
      localStorage.setItem('zid', state.zid);
    } else {
      console.warn("Ungültige ID für zid erhalten: ", id);
    }
  },
  
  setZyklen: (state, zyklen) => {
  let a =   store.getters.groups;
    if (!a) {
      console.error("store.state.config.groups is not initialized");
      return;
    }
    if (zyklen !== null) {
      zyklen = changeZyklen(zyklen);
    }
    state.zyklen = zyklen;
  },
  ZyknsetPiC: (state, payload) => {
    let g = store.state.config.groups.userColleaguesFull;
    if (payload.itemId && state.zyklen) {
      let zyklus = state.zyklen.find(item => item.Id === payload.itemId);
  
      if (zyklus) {
        zyklus.PiCId.results = payload.PiCId;
  
        if (g && zyklus.PiCId && zyklus.PiCId.results) {
          let PiCIds = zyklus.PiCId.results.map(r => r.Id); // Extrahiere die Ids aus zyklus.PiCId.results
          let PiCfull = g.filter((i) => PiCIds.includes(i.Id));
          let PiCmapped = PiCfull.map(t => t.Title);
          zyklus.Verantwortlich = PiCmapped.toString();
        }
  
        let lockstatus;
        if (zyklus.PiCId.results && store.state.config.user.userId) {
          lockstatus = zyklus.PiCId.results.includes(store.state.config.user.userId);
        } else {
          lockstatus = false;
        }
  
        zyklus.unlocked = lockstatus;
      }
    }
  },
  
  setZyklenLoading: (state, loading) => {
    state.loading = loading
  },
  addZyklus: (state, zyklus) => {
    if(zyklus) {
      if(state.zyklen === null) state.zyklen = [zyklus]
      else state.zyklen.unshift(zyklus)
    }
  },
  deleteCycl: (state, id) => {
    if(id && Array.isArray(state.zyklen)) state.zyklen = state.zyklen.filter(item => item.Id !== id)
  },
  addZyklusEb: (state, payload) => {
    // add eb in vuex
    let zyklus = store.getters.zyklusById(payload)
    let eb = zyklus.EB
    if(zyklus && Array.isArray(eb) && eb.length) eb.push(payload.eb)
    else if(zyklus && payload.eb) zyklus.EB = [payload.eb]
    // save eb in data storage
    store.dispatch("saveZyklusEb", {id: payload.sid, zid: payload.zid, eb: zyklus.EB})
  },
  removeZyklusEb: (state, payload) => {
    // remove eb in vuex
    let zyklus = store.getters.zyklusById(payload)
    let eb = zyklus.EB
    if(zyklus && Array.isArray(eb) && eb.length && payload.eb) {
      const index = eb.indexOf(payload.eb);
      if (index > -1) {
        eb.splice(index, 1);
      }
    }
    // save eb in data storage
    store.dispatch("saveZyklusEb", {id: payload.sid, zid: payload.zid, eb: zyklus.EB})
  },
  setAbschluss: (state, payload) => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) zyklus.Abschluss = payload.abschluss
    // save abschluss in data storage
    store.dispatch("saveZyklusAbschluss", payload)
  },
  setEval: (state, payload) => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) zyklus.Eval = payload.eval
    // save abschluss in data storage
    store.dispatch("saveZyklusEval", payload)
  },
  setKlasse: (state, payload) => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) {
      zyklus.Klasse = payload.Klasse
      // save klasse in data storage
      store.dispatch("saveZyklusKlasse", payload)
    }
  },
  setStartDate: (state, payload) => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) {
      zyklus.Anfangsdatum = payload.Anfangsdatum
      store.dispatch("saveZyklusStartDate", payload)
    }
  },
  setEndDate: (state, payload) => {
    let zyklus = store.getters.zyklusById(payload)
    if(zyklus) {
      zyklus.Enddatum = payload.Enddatum
      store.dispatch("saveZyklusEndDate", payload)
    }
  },
  setZyklusPiC: (state, payload) => {
    let zyklus = store.getters.zyklusById(payload)
    if (zyklus) {
      let metadata = { "__metadata": { "type": "Collection(Edm.Int32)" } };

      zyklus.PiC = { ...metadata, "results": payload.pic };
      zyklus.PiCId = { ...metadata, "results": payload.pic.map(p => p.Id) };
  

      zyklus.colleagues = payload.pic.map(p => p.Title)
    }
  },
  setLoadAttempted(state, value) {
    state.loadAttempted = value;
  },
  setLoadFailed(state, value) {
    state.loadFailed = value;
  },
  setCurrentZyklus(state, zyklus) {
    state.currentZyklus = zyklus;
  },
}

function changeZyklen(val) {
  let g = store.getters.api === "pepApi" ? null : store.state.config.groups.userColleaguesFull;

  if (Array.isArray(val)) {
    val.forEach((obj) => {
      // Metadata für PiCId und PiC setzen, falls sie nicht dem erwarteten Format entsprechen
      if (!obj.PiCId || !Array.isArray(obj.PiCId.results)) {
        obj.PiCId = {
          "__metadata": {
            "type": "Collection(Edm.Int32)"
          },
          "results": [parseInt(obj.Author.Id)]
        };
      }

      if (!obj.PiC || !Array.isArray(obj.PiC.results)) {
        obj.PiC = {
          "__metadata": {
            "type": "Collection(Edm.Int32)"
          },
          "results": [parseInt(obj.Author.Id)]
        };
      }

      // Log-Ausgabe für Debugging
      console.log("Debug PiCId:", obj.PiCId);
      console.log("Debug PiC:", obj.PiC);

      // Verantwortliche und Lock-Status setzen
      if (g) {
        let PiCIds = obj.PiCId.results;
        let PiCfull = g.filter((i) => PiCIds.includes(i.Id));

        if (PiCIds.length !== PiCfull.length) {
          console.warn('Nicht alle Verantwortlichen gefunden:', PiCIds, PiCfull);
        }

        let PiCmapped = PiCfull.map(t => t.Title);
        obj.Verantwortlich = PiCmapped.toString();
        obj.unlocked = PiCIds.includes(store.state.config.user.userId);
      }
    });
  } else if (val !== null) {
    // (Ihr Code für Einzelobjekte hier, ähnlich wie im Array-Fall)
  }

  console.log(val); // Log-Ausgabe
  return val;
}
export const waitForZyklus = (payload) => {
  console.log('waitForZyklus called');
  return new Promise((resolve, reject) => {
    let timeoutId = setTimeout(() => {
      console.log('waitForZyklus timed out');
      reject(new Error('Timed out waiting for Zyklus data.'));
    }, 5000); // Timeout nach 5000 Millisekunden (5 Sekunden)

    let intervalId = setInterval(() => {
      console.log('checkData in waitForZyklus');
      let zyklus = store.getters.zyklusById({ zid: payload });
      if (zyklus) {
        console.log('Zyklus data found in waitForZyklus');
        clearTimeout(timeoutId); // Löschen des Timeouts, wenn die Daten verfügbar sind
        clearInterval(intervalId); // Löschen des Intervals
        resolve(zyklus);
      }
    }, 100); // Überprüfen Sie die Daten alle 100 Millisekunden
  })
};



export default {
  state,
  mutations,
  getters,
  actions
}
