<style>


.removeButtonStyle {
  all: inherit;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 !important;
  margin: 0;
  border: 0;
  font-size: 18px !important;
}

.profil-zyklen-loading-container {
  margin: 50px;
  width: 750px;
  height: 450px;
  cursor: default;
  border: none;
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
  box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
  background: hsla(0,0%,100%,.4);
  opacity: .4;
  font-family: aileronheavy;
  display: grid;
  font-size: 40px;
  align-content: center;
  justify-items: center;
}
.profil-zyklen-loading-card {
  width: auto;
height: 100%;
cursor: default;
border: none;
border-radius: 3px;
box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
background: hsla(0,0%,100%,.4);
opacity: .8;
font-family: aileronheavy;
display: grid;
font-size: 30px;
align-content: center;
justify-items: center;
position: relative;
text-align: center;
}

.thumbnail-card {
  background: hsla(0,0%,100%,.6);
  width: 150px;
height: 110px;
backdrop-filter: blur(10px)
}
@keyframes animate {
  0%, 100% {
    transform: translateX(0);  /* Startposition */
    opacity: .5;
    box-shadow: var(--shadow-elevation-low);
  }
  25% {
    transform: translateX(calc(var(--left-value)));  /* Endposition */
    opacity: 1;
    box-shadow: var(--shadow-elevation-low);
  }
  75% {
    transform: translateX(calc(var(--left-value)));  /* Endposition */
    opacity: .8;
    box-shadow: var(--shadow-elevation-medium);
  }
  90% {
    transform: translateX(0);  /* Startposition */
    opacity: .5;
    box-shadow: var(--shadow-elevation-low);
  }
}
.thumbnail-card span {
    position: absolute;
    width: 150px;
    height: 110px;
    opacity: 1;
    /* background: linear-gradient(#a8a5a4, #84cdfa, #5ad1cd); */
    z-index: -2;
    background-color: hsla(0,0%,100%,.6)
}

.thumbnail-card span:nth-child(1) {
    --left-value: 105%;
    left: 0;
    animation: animate 3.4s ease infinite .1s;
}
.thumbnail-card span:nth-child(2) {
    --left-value: 210%;
    left: 0;
    animation: animate 3.4s ease infinite .2s;
}
.thumbnail-card span:nth-child(3) {
    --left-value: 315%;
    left: 0;
    animation: animate 3.4s ease infinite .3s;
}
.thumbnail-card:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #ffffffc4;
    border: solid white 4px;
    border-radius: 0%;
    z-index: -1;
    backdrop-filter: blur(20px)
}
.profil-zyklen-container {
  margin-bottom: 40px;
  color: #2c3e50;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  margin-left: 33px;
  margin-right: 33px;
}

.zyklus-slide-row-container {
  /* TODO: this is hacky - maybe move the part to an upper component */
  margin-top: -150px;
}

.zyklus-primary-slide {
  cursor: default;
  border: none;
  border-radius: 3px;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  background: hsla(0,0%,100%,.1);
  opacity: 0.4;
  display: grid;
}

.zyklen-secondary-splide {
  margin: 20px 0px 30px 0px;
}

.zyklen-secondary-splide ul.splide__list li:nth-child(1),
.zyklen-secondary-splide ul.splide__list li:nth-child(2) {
  width: 110px !important;
  height: 80px !important;
}

.zyklen-secondary-splide .splide__slide.is-active {
  width: 170px !important;
  height: 130px !important;
}

@media screen and (min-width: 1450px) {
  .zyklen-secondary-splide ul.splide__list li:nth-child(1),
  .zyklen-secondary-splide ul.splide__list li:nth-child(2) {
    width: 190px !important;
    height: 190px !important;
  }
  .zyklen-secondary-splide .splide__slide.is-active {
    width: 190px !important;
    height: 190px !important;
  }
  .thumbnail-card span {
    position: absolute;
    width: 190px;
    height: 190px;
    opacity: 1;
    /* background: linear-gradient(#a8a5a4, #84cdfa, #5ad1cd); */
    z-index: -2;
}

.thumbnail-card {
  background: hsla(0,0%,100%,.6);
  width: 190px;
height: 190px;
backdrop-filter: blur(10px)
}
}
/* Überprüfung, ob aspect-ratio unterstützt wird */
@supports (aspect-ratio: 1/1) {
  .thumbnail-card,
  .zyklen-secondary-slide,
  .thumbnail-card span {
    aspect-ratio: 1/1;
  }

  /* Media Query für Bildschirme mit einer maximalen Breite von 1300px */
  @media only screen and (max-width: 1300px) {
    @supports (aspect-ratio: 1/4.5) {
      .thumbnail-card,
      .zyklen-secondary-slide,
      .thumbnail-card span {
        aspect-ratio: 1/4.5;
      }
    }
  }
}


.slide-new {
  background: rgb(255,241,186);
  background: linear-gradient(130deg, rgba(255,241,186,1) 0%, rgba(144,246,216,1) 46%, rgba(116,245,207,1) 74%, rgba(98,207,206,1) 100%);
}

.zyklen-secondary-slide {
  font-family: aileronheavy;
  font-size: 18px;
  line-height: 1em;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  cursor: pointer;
  background-color: #ffffff4f;
  box-shadow: var(--button-elevation-low);
  border: 1px solid #dfe9f357;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(223, 233, 243, 0.34);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(223, 233, 243, 0.34);
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(223, 233, 243, 0.34);
border-left-width: 1px;
border-left-style: solid;
border-left-color: rgba(223, 233, 243, 0.34);
border-bottom: 1px solid #4d4d4d85;
border-right: 1px solid #9f9f9f;
mix-blend-mode: multiply;
background-blend-mode: multiply;
width: 100%;

}
.zyklen-secondary-slide::before {

content: "";
position: absolute;
height: 100%;
width: 100%;
left: 0;
top: 0;
z-index: -1;
background: #ffffff0a;
backdrop-filter: blur(1px);
transition: box-shadow .3s ease;
background: radial-gradient(107% 129% at 84% 0%, #fff 15%, #202020a3 113% );
opacity: .3;
filter: brightness(1.2) contrast(1.1);

}
.is-active > .zyklen-secondary-slide {
  box-shadow: var(--button-elevation-high);
  transition: box-shadow .3s ease;
}
@media screen and (min-width: 1450px) {
  .zyklen-secondary-slide {
    font-size: 30px;
  }
   .zyklen-secondary-splide .splide__slide   {
    width: 190px !important;
    height: 190px !important;
  }
  .thumbnail-card span {
    position: absolute;
    width: 190px;
    height: 190px;
    opacity: 1;
    /* background: linear-gradient(#a8a5a4, #84cdfa, #5ad1cd); */
    z-index: -2;
}

.thumbnail-card {
  background: hsla(0,0%,100%,.6);
  width: 190px;
height: 190px;
backdrop-filter: blur(10px)
}
}
.zyklen-secondary-splide > .splide__track {
  overflow: visible;
}

/* css for splide */
.is-active {
  opacity: 1;
}

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: none;
  background-color: white;
}

.splide-arrow {
  width: 60px;
  height: 60px;
  border: 1px solid #ffffffb0;
  background-color: #a1a1a175;
}

.splide-arrow:disabled {
  opacity: 0.5;
  cursor: default;
}

.splide-arrow-prev {
  left: -80px;
}
.splide-arrow-next {
  right: -80px;
}


/* new zyklus */
.new-zyklus-card-title {
  letter-spacing: 1.2px;
  font-size: calc(30px + 6 * (100vh - 300px) / 1600);
  margin-top: 15px;
  margin-bottom: 15px;
}

.banner-title {
  font-size: calc(36px + (52 - 36) * ((100vh - 300px) / (1900 - 300))) !important;
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  width: 100%;
  text-align: left;
     text-align: -webkit-left;
   text-align: -moz-left;
  font-weight: 700;
  color: rgb(36 50 64);
  font-family: "aileronsemibold", sans-serif;
}

.banner-slide {
  margin: 16px;
  padding: 30px 55px 60px 55px;
  align-self: center;
  justify-self: left;
}

.form-cycl-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "Klassenstufe Zeitraum"
    ". ."
    "Button .";
}

.Klassenstufe {
  grid-area: Klassenstufe;
  align-self: center;
}

.Zeitraum {
  grid-area: Zeitraum;
}

.Button {
  grid-area: Button;
}

.zyklus-card-class {
  font-size: 19px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: min-content min-content;
}
.zyklus-number {
  font-family: RobotoMono-medium;
font-size: 450%;
position: absolute;
text-align: center;
z-index: -2;
color: gray;
width: 100%;
text-shadow: 2px 4px 4px #3b364ba8;
mix-blend-mode: color-burn;
}
.zyklus-card-class-value {
  margin-left: 5px;
  max-width: 40px;
}

.zyklus-card-date-picker {
  max-width: 300px;
  color: black;
}
.fv-DatePicker__input > button {
  font-size: 16px;
}

.fv-DatePicker__input-options {
  width: 150% !important;
  height: 400px !important;
  overflow: unset !important;
  top: 25vh;
  width: 100% !important;
  position: fixed;
}@media only screen and (max-width: 1300px) {
.thumbnail-card {
  background: hsla(0,0%,100%,.6);
  width: 150px;
height: 110px;
backdrop-filter: blur(10px)
}
}
@media only screen and (min-width: 1300px) {

  .fv-DatePicker__input-options {
    top: 20vh;
  }

}

.fv-light-DatePicker .fv-DatePicker__input-options .fv-DatePicker__input-body .fv-DatePicker__input-options-col .fv-DatePicker__input-options-col-items .fv-DatePicker__input-options-col-item {
  font-size: 17px !important;
}

.fv-light-DatePicker .fv-DatePicker__input-options .fv-DatePicker__input-options-bottom-bar button {
  font-size: 19px !important;
}

.zyklus-card-author {
  line-height: 1;
  margin-bottom: 10px;
}

.zyklus-card-period-container {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1;
}

.zyklus-card-period {
  font-size: 16px;
  margin-top: 2px;
}

.new-period-container {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: min-content min-content;
  align-items: center;
  grid-gap: 10px;
  margin-top: 10px;
}

.new-zyklus-input {
  font-family: aileronregular;
  font-size: calc(13px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
}

.new-zyklus-button-container {
  margin-top: 25px;
  display: grid;
  grid-template-rows: 1.5em;
}

.new-zyklus-button {
  font-size: calc(13px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
  width: 185px !important;
  height: 40px !important;
}

.timerange-button {
  margin-top: 10px;
  font-size: calc(13px + (22 - 16) * ((100vh - 300px) / (1900 - 300))) !important;
  width: 185px !important;
  height: 40px !important;
}

.button-container {
  background-color: grey;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.banner-new-slide {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "warning-new-cycl"
    "banner-new-combo";
  margin: 16px;
  padding: 30px 55px 60px 55px;
  align-items: center;
}

.warning-new-cycl {
  font-family: 'aileronsemibold';
  line-height: 1.2em;
  font-size: calc(18px + (26 - 18) * ((100vh - 300px) / (1900 - 300))) !important;
  grid-area: warning-new-cycl;
}

.Evaluated {
  align-self: start;
  grid-area: banner-new-combo;
  padding-top: 15px;
  border-left: 1px solid black;
}

.Checkbox-Evaluated {
  grid-area: Checkbox-Evaluated;
  justify-self: center;
}

.Text-Evaluated {
  grid-area: Text-Evaluated;
  align-self: center;
  font-family: 'aileronheavy';
  font-size: calc(20px + (24 - 20) * ((100vh - 300px) / (1900 - 300))) !important;
}

.Go-to-Eval {
  margin-top: 20px;
}
.goto-eval-button {
  height: 45px !important;
  width: 300px !important;
  margin-right: 90px !important;
  border: 1px solid #a7a7a742;
}

.ms-Icon--Forward {
  margin-right: 15px !important;
}


/* zyklus card */
.primary-slide-container {
  display: grid;
  grid-template-rows: min-content min-content;
  padding-bottom: 20px;
}

.primary-slide-header-container {
  padding: 80px 30px 30px 70px;
  display: grid;
  grid-template-columns: auto auto;
}

.primary-slide-header {
  display: grid;
  grid-template-rows: min-content min-content;
}

.latestFlag {
  /* font-family: 'aileronsemibold';
  background-image: linear-gradient(120deg, rgb(212 252 121 / 40%) 0%, rgb(150 230 161 / 40%) 100%);
  padding: 8px 0 8px 0;
  visibility: hidden; */
  position: absolute;
left: 0;
width: 100%;
padding: 20px 0 15px 70px;
font-family: 'aileronsemibold';
background-image: linear-gradient(90deg, rgba(212, 252, 121, 0.5) -10%, rgba(150, 230, 161, 0.3) 40%, rgba(150, 230, 161, 0.0) 80%);
visibility: hidden;
top: 0

}

.primary-slide-header-text {
  font-size: calc(33px + .71vw) !important;
  font-weight: 700;
  color: rgb(36 50 64);
  font-family: aileronsemibold,sans-serif;
  line-height: calc(1.3em + .02308vw - .06923px);
}

.visible {
  visibility:visible;
}

.primary-slide-persona {
 justify-self: end;
 align-self: start;
 line-height: 1em;
 margin-right: 50px;
}


.primary-slide-main-container {
  padding: 10px 70px 10px 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.primary-slide-footer {
   padding: 10px 70px 10px 70px;
   display: flex;
   margin-bottom: 10px;
}

.primary-slide-footer .delete-button-container {
  align-self: center;
  margin-left: auto;
  cursor: pointer;
}

.removeButtonStyle {
  all: inherit;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 !important;
  margin: 0;
  border: 0;
  font-size: 18px !important;
}
.form-button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #ffffffb3;
  color: rgb(68, 68, 68);
  width: 300px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  border: 1px solid black;
  margin: 0;
  font-weight: 600;
}
.slide-main-date-container {
  font-size: 18px;
  color: #2c3e50;
  font-family: aileronregular;
}

.slide-main-class-container {
  font-size: 18px;
}

.padding-top {
  padding-top: 15px;
}

.small-margin {
  margin-bottom: 7px;
}

.slide-main-label {
  margin-bottom: 5px;
  font-family: aileronheavy;

}

.slide-main-hyperlink-container {
  display: flex;
  margin-right: 20px;
  background: rgb(255,241,186);
  background: linear-gradient(130deg, rgba(255,241,186,1) 0%, rgba(144,246,216,1) 46%, rgba(116,245,207,1) 74%, rgba(98,207,206,1) 100%);
  padding: 3px 10px;
  cursor: pointer;
}

.slide-main-hyperlink-text {
  font-family: aileronheavy;
  font-size: 18px;
}
.slide-main-checkbox {
  margin-left: 10px;
}
.slide-main-hyperlink {
  display: grid;
}

.goto-button {
  margin-left: -20px;
  height: 35px !important;
  width: 230px !important;
  border: 1px solid #a7a7a742;
}

.hidden {
  visibility: hidden;
}

.banner-all-zyklen {
  padding: 66px 0 0 71px;
}

input[type="checkbox"]{
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: white;
  border: 1px solid black;
  width: 23px;
  height: 23px;
  transition: all .3s linear;
}
input[type="checkbox"]:checked{
  background-color: #2ECC71;
}

.sync-status-container {
  font-size: 17px;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: flex-start;
  grid-gap: 10px;
  margin: 50px 25px 25px 25px;
}

.slide-main-planning-eval-container {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.slide-main-planning-eval-container .action-status-button {
  margin-right: 24px;
}

.primary-slide-headline-container {
  display: flex;
}

.zyklus-splide-container-secondary .splide__list li:nth-child(3) {
  height: auto !important;
}

.splide__list {
  transition: max-height .3s cubic-bezier(.19,1,.22,1);
  max-height: 450px;
}
.height-150 {
  max-height: 150px;
  transition: max-height .3s cubic-bezier(.19,1,.22,1);
}
.zyklus-splide-container-secondary .splide__track {
  overflow-x: clip;
  overflow-y: visible;
}
</style>

<template>
<div class="profil-zyklen-container">
  <!-- slides used as thumbnails -->
  <div class="zyklus-splide-container zyklus-slide-row-container">
    <splide :options="secondaryOptions" ref="secondary" class="zyklen-secondary-splide" :slides="zyklen"  @splide:move="updateCurrentIndex" >
      <splide-slide :key=0>
        <div class="zyklen-secondary-slide slide-new">
          Neu
        </div>
      </splide-slide>
      <splide-slide key=-1>
        <div class="zyklen-secondary-slide slide-all">
          Alle
        </div>
      </splide-slide>
      <template  v-if="isLoading || nextZyklusLoading">
        
      <splide-slide >

        <div class="profil-zyklen-loading-card thumbnail-card">
        <span  :class="colorClass" ></span>
        <span  :class="colorClass" ></span>
        <span  :class="colorClass" ></span>


    
</div>
      </splide-slide>
    </template>
    <template v-else>
      <splide-slide v-for="(zyklus, index) in zyklen" :key="zyklus.Id"   ref="splideRef">
        <div class="zyklen-secondary-slide cyl-slide-secondary" :class="colorClass" :style="colorFilter(index)" @click="handleClick(zyklus, index)"  >
      
     <span>{{ zyklus.Title | extractZyklus }}</span>
     <span class="zyklus-number">{{ zyklus.Title | extractNumber }}</span>
        </div>
      </splide-slide>
    </template>
    </splide>
  </div>

  <!-- slides used as zyklus card -->
  <div class="zyklus-splide-container zyklus-splide-container-secondary">
    <splide :options="primaryOptions" ref="primary" :slides="zyklen" class="splider" @splide:moved="splideMoved" >
      <template  v-if="isLoading || nextZyklusLoading">
 
 <splide-slide >
   <div class="profil-zyklen-loading-card">
<div>
 Lade Zyklen...
</div>
<div v-if="nextZyklusLoading" class="sync-status-container">
 <input type="checkbox" :checked="syncSucces.addZyklus" id="zyklusSync" disabled/>
 <label for="zyklusSync">Füge Zyklus hinzu</label>
 <input type="checkbox" :checked="syncSucces.addGoals" id="goalsSync" disabled/>
 <label for="goalsSync">Synchronisiere mit den Zielen des letzen Zyklus</label>
 <input type="checkbox" :checked="syncSucces.addMeasures" id="measuresSync" disabled/>
 <label for="goalsMeasures">Synchronisiere mit den Maßnahmen des letzen Zyklus</label>
</div>
</div>
 </splide-slide>
</template>
<template v-else>
      <!-- new zyklus -->
      <splide-slide class="zyklus-primary-slide">
        <div v-if="lastZyklusIsEvaluated" class="banner-slide">
            <div class="new-zyklus-card-title banner-title">
              {{ nextZyklusTitle }}
            </div>
            <div class="form-cycl-container">
              <div v-if="isFirstZyklus" class="Klassenstufe">
                <div class="zyklus-card-class">
                  Klassenstufe:
                  <input class="new-zyklus-input zyklus-card-class-value" v-model="newZyklus.klasse" type="text" autocomplete="off" />
                </div>
              </div>
              <div v-if="isFirstZyklus" class="Zeitraum">
                <div class="zyklus-card-period-container">
                  Zeitraum:
                  <div class="zyklus-card-period new-period-container">
                    <div>{{showTimePeriod ? 'Startdatum' : 'von'}}</div>
                    <fv-DatePicker class="zyklus-card-date-picker" v-model="newZyklus.startDate" :months="months" :key="datePickerKey+'startDate'"></fv-DatePicker>
                    <div>{{showTimePeriod ? 'Dauer' : 'bis'}}</div>
                    <fv-DropDown v-if="showTimePeriod" v-model="timeRange" :options="timeRangeOptions" placeholder="Zeitraum wählen"></fv-DropDown>
                    <fv-DatePicker v-if="!showTimePeriod" class="zyklus-card-date-picker" v-model="newZyklus.endDate" :months="months" :key="datePickerKey+'endDate'"></fv-DatePicker>
                    <fv-button class="timerange-button" @click.stop.prevent="showTimePeriod = !showTimePeriod" fontSize="15" icon="Calendar" theme="dark">{{showTimePeriod ? 'Enddatum wählen' : 'Zeitraum wählen'}}</fv-button>
                  </div>
                </div>
              </div>
              <div class="Button">
                <div class="new-zyklus-button-container">
                  <fv-button class="new-zyklus-button" @click.stop.prevent="addZyklus" fontSize="15" icon="CalculatorAddition" theme="dark">Zyklus hinzufügen</fv-button>
                </div>
              </div>
            </div>
        </div>

        <!-- new zyklus: if last zyklus not evaluated -->
        <div class="banner-new-slide" v-if="!lastZyklusIsEvaluated">
          <span class="warning-new-cycl">
            Das Erstellen eines neuen Zyklus ist erst <u>nach</u> der Evaluierung des vorangegangenen Zyklus möglich
          </span>
          <div v-if="latestZyklus" class="Evaluated" style="border-left: none">
            <div class="Text-Evaluated"><span>{{latestZyklus.Title}} jetzt evaluieren</span></div>
            <div class="Go-to-Eval">
               <ActionStatusButton
                  :active="true"
                  :checked="latestZyklus.Eval"
                  uncheckedText="Evaluieren"
                  checkedText="Evaluiert"
                  :navigateTo="() =>goToCylEval(latestZyklus.Id)"
                />
            </div>
          </div>
        </div>
        <slot></slot>
      </splide-slide>
    </template>
   
      <!-- all zyklen -->
    
      <splide-slide class="zyklus-primary-slide" ref="alleZyklen">
        <div class="banner-title banner-all-zyklen">
          Alle Zyklen
        </div>
        <slot></slot>
      </splide-slide>




      <splide-slide v-for="(zyklus, index) in zyklen" :key="zyklus.Id" class="zyklus-primary-slide" >
        <div class="primary-slide-container cyl-slide-primary">
          <!-- card header -->
          <div class="primary-slide-header-container">
            <div class="primary-slide-header">
              <div :class="{'visible': index === 0}" class="latestFlag">
                Aktueller Zyklus
              </div>
              <div class="primary-slide-headline-container">
                <div class="primary-slide-header-text">
                  {{ zyklus.Title }}
                </div>
              </div>
            </div>
            <div class="primary-slide-persona">
              <fv-Persona size="40" :name="zyklus.Author.Title" :showInfo="true">
                <template v-slot:secondary>
                  <p>Ersteller:in</p>
                </template>
              </fv-Persona>
            </div>
          </div>
          <!-- card main -->
          <div class="primary-slide-main-container">
            <div class="slide-main-date-container">
              <div class="slide-main-label">
                Zeitraum
              </div>
              {{ zyklus.StartDate | dateformer }} -
              {{ zyklus.OData__EndDate | dateformer }}
            </div>
            <div class="slide-main-class-container">
              <div class="slide-main-label">
                Klassenstufe
              </div>
              {{ zyklus.Klasse }}
            </div>
          </div>
            <!-- card footer -->
          <div class="primary-slide-footer">
              <div class="slide-main-planning-eval-container">
                <ActionStatusButton
                  :active="true"
                  :checked="zyklus.Abschluss"
                  uncheckedText="Planen"
                  checkedText="Geplant"
                  :navigateTo="() => goToCyl(zyklus.Id)"
                />

                <!-- Evaluieren-Button -->
                <ActionStatusButton
                  :active="zyklus.Abschluss"
                  :checked="zyklus.Eval"
                  uncheckedText="Evaluieren"
                  checkedText="Evaluiert"
                  :navigateTo="() => goToCylEval(zyklus.Id)"
                ></ActionStatusButton>
               
              </div>
            <DeleteButton v-if="index === 0" @delete="deleteCycl" />
          </div>
        </div>
        <slot></slot>
      </splide-slide>

    </splide>
  </div>
</div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import ActionStatusButton from '../Buttons/ActionStatusButton.vue';
import DeleteButton from '../Buttons/DeleteButton.vue';


export default {
  components: {
    Splide,
    SplideSlide,
    ActionStatusButton,
    DeleteButton
  },
  props: ["zyklen", "sid", "routeNow", "componentLoaded"],
  data() {
    return {
      nextZyklusLoading: false,
      zyklus: null,
      datePickerKey: 0,
      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      timeRangeOptions: [
        { key: 2, text: "2 Wochen" },
        { key: 4, text: "4 Wochen" },
        { key: 6, text: "6 Wochen" },
        { key: 8, text: "8 Wochen" },
        { key: 10, text: "10 Wochen" },
        { key: 12, text: "12 Wochen" },
        { key: 16, text: "16 Wochen" },
      ],
      timeRange: [],
      showTimePeriod: true,
      newZyklus: {
        klasse: null,
        startDate: new Date(),
        endDate:  new Date(new Date().setDate(new Date().getDate() + (8 * 7))) // 8 Wochen = 8 * 7 Tage
      },
      primaryOptions: {
        //type: 'loop',
        width: '100%',
        perMove: 1,
        gap: '1rem',
        pagination: false,
        arrows: false,
        classes: {
          arrow: "splide__arrow splide-arrow",
          prev: "splide__arrow--prev splide-arrow-prev",
          next: "splide__arrow--next splide-arrow-next"
        }
      },
      secondaryOptions: {
        fixedHeight: 200,
        fixedWidth: 200,
        pagination: false,
        isNavigation: true,
        gap: '1rem',
        arrows: false,
        drag: true,
        focus: 0,

        breakpoints: {
          1450: {
            fixedHeight: 110,
            fixedWidth: 150,
          },
        }
      },
      syncSucces: {
        addEbereiche: false,
        addGoals: false,
        addMeasures: false,
        addZyklus: false
      },
      initialLoad: true,
      newZyklusAdded: false,
      lastZyklusLength: 0,
      currentIndex: 0
    }
  },
  watch: {
    zyklen: {
    immediate: true,
    handler(newVal, oldVal) {
      console.log("newVal:", newVal);  // Überprüfen Sie den Wert von newVal
      console.log("oldVal:", oldVal);  // Überprüfen Sie den Wert von oldVal
      this.syncSplider()

      if (newVal && oldVal) {
        this.$nextTick(() => {
          console.log("this.$refs.primary:", this.$refs.primary);  // Überprüfen Sie, ob this.$refs.primary existiert
          console.log("this.$refs.secondary:", this.$refs.secondary);  // Überprüfen Sie, ob this.$refs.secondary existiert

          if (this.$refs.primary && this.$refs.primary.splide && this.$refs.primary.index < 2) {
            console.log("this.$refs.primary.splide:", this.$refs.primary.splide);  // Überprüfen Sie, ob this.$refs.primary.splide existiert
            console.log("zyklen watcher")
            this.setSplideIndexByClass()
          }

          if (this.$refs.secondary && this.$refs.secondary.splide) {
            console.log("watch", "secondary index ", this.$refs.secondary.index , "primnary index ", this.$refs.primary.index )
            console.log("this.$refs.secondary.splide:", this.$refs.secondary.splide);  // Überprüfen Sie, ob this.$refs.secondary.splide existiert
          }
        });
      }
    }
},

    timeRange: function(){
      const timeRange = this.timeRange[0]
      const startDate = new Date(this.newZyklus.startDate)
      this.newZyklus.endDate = new Date(new Date().setDate(startDate.getDate() + (timeRange.key * 7)))
    },
    activeSlide() {
      // Überprüfen, ob der spezielle Ref 'alleZyklen' existiert
      if (this.$refs.alleZyklen) {
        // Überprüfen, ob 'is-active' und 'is-visible' in den Klassen vorhanden sind
        const classList = this.$refs.alleZyklen.$el.classList;
        if (classList.contains('is-active') && classList.contains('is-visible')) {
          // Überprüfen, ob der spezielle Ref 'primary' existiert
          if (this.$refs.primary) {
            const splideList = this.$refs.primary.$el.querySelector(".splide__list");
            // Überprüfen, ob '.splide__list' existiert
            if (splideList) {
              // Setze die Höhe auf 150px
              splideList.style.height = "150px";
            }
          }
        }
      }
    },
    latestZyklus(newVal, oldVal) {
    // Rufen Sie die Methode auf, wenn sich latestZyklus ändert.
    if (newVal !== oldVal) {
      this.updateNewZyklusKlasse();
    }
  }
  },
  mounted() {
  console.log("Received id:", this.sid);
  console.log("Received zyklen:", this.zyklen);
  this.syncSplider()
  this.updateNewZyklusKlasse();
  this.$watch(
    () => this.isLoading,
    (newVal) => {
      console.log("this.isLoading:", newVal);  // Überprüfen Sie den Wert von this.isLoading

      if (!newVal) {
        this.$nextTick(() => {
           if (this.$refs.primary && this.$refs.primary.splide && this.$refs.primary.splide.length >= 3 && this.$refs.primary.index < 2) {
             console.log("primary splide exists and has enough slides");
       //      console.log("mounted", this.$refs.primary,  this.$refs.primary.splide,  this.$refs.primary.splide.length, this.$refs.primary.index, this.$refs.splideRef)
             setTimeout(() => {
              this.setSplideIndexByClass()
           },500)
             

           }
          if (this.$refs.secondary && this.$refs.secondary.splide && this.$refs.secondary.splide.length >= 3 ) {
            console.log("secondary splide exists and has enough slides");
        //    console.log("mounted", this.$refs.secondary,  this.$refs.secondary.splide,  this.$refs.secondary.splide.length, this.$refs.secondary.index)
          //  console.log("mounted secondary", "secondary index ", this.$refs.secondary.index , "primnary index ", this.$refs.primary.index )

          }
          
        });
      }
    },
    { immediate: true }
  );
  
  },
  computed: {
    colorClass() {
      return this.$store.getters.getColorClass;
    },
    isSidNumber() {
      
      console.log(typeof this.sid)
    return typeof this.sid === 'number';
  },
    userTitle() {
      return this.$store.getters.userTitle
    },
    userId() {
      return this.$store.getters.userId
    },
    isLoading() {
       return this.$store.getters.zyklenLoading
    },
    latestZyklus() {
      if (Array.isArray(this.zyklen) && this.zyklen.length) {
        return this.zyklen[0]
      } else
      return null
    },
    isFirstZyklus() {
      return this.zyklen && this.zyklen.length === 0
    },
    lastZyklusIsEvaluated() {
      // zyklen not loaded
      if(this.zykn === null) return false
      // latest zyklus exists
      else if(this.latestZyklus) return this.latestZyklus.Eval
      // latest zyklus does not exists
      else return true
    },
    nextZyklusTitle() {
      if (Array.isArray(this.zyklen) && this.zyklen.length) {
        // get number from next zyklus
        let count = 0;
        for (let zyklus of this.zyklen) {
          if (zyklus && zyklus.Title) {
            let regex = /\./;
            // parse first part of title as number
            let zyklusCount = parseInt(zyklus.Title.split(regex)[0]);
            // get the highest title number
            if (Number.isInteger(zyklusCount) && zyklusCount > count)
              count = zyklusCount;
          }
        }
        // add one for next zyklus
        count++;
        return `${count}. Zyklus`;
      } else {
        // there exists no zyklus for this student
        return "1. Zyklus";
      }
    }
  },
  filters: {
    extractNumber: function(value) {
    if (value) {
      const number = value.split(". ")[0];
      return number.length === 1 ? `0${number}` : number;
    }
  },
  extractZyklus: function(value) {
    if (value) {
      return value.split(". ")[1];
    }
  }
  },
  methods: {
    updateCurrentIndex() {
      console.log( this.$refs.secondary.index)
      this.currentIndex = this.$refs.secondary.index;
    },
    handleClick(zyklus, index) {
      console.log(zyklus, index)
      if (this.isCurrentSlide(index)) {
        this.goToCyl(zyklus.Id);
      } 
    },
    isCurrentSlide() {
      const currentSlideIndex = this.$refs.secondary.index
      console.log(currentSlideIndex)
      return this.currentIndex === currentSlideIndex ;
    
    },
      updateNewZyklusKlasse() {
    if (this.latestZyklus && this.latestZyklus.Klasse) {
      const klasseMatch = this.latestZyklus.Klasse.match(/(\d+)([a-zA-Z]*)/);
      let nummer = klasseMatch ? parseInt(klasseMatch[1], 10) : null;
      let buchstabe = klasseMatch && klasseMatch[2] ? klasseMatch[2] : '';

      if (nummer !== null) {
        nummer++; // Nummer inkrementieren
      }

      // Aktualisieren Sie das newZyklus-Objekt mit der neuen Klasse
      this.newZyklus.klasse = `${nummer !== null ? nummer : ''}${buchstabe}`;
    }
  
  },
    splideMoved(splider) {
     
      if(Array.isArray(this.zyklen) && this.zyklen.length) {
        let index = splider.index
        let zyklusId = null 
        if (index > 1) {
          zyklusId = this.zyklen[index - 2].Id
        }
        this.$emit("updateZyklusId", zyklusId)
      } 
     
      this.adjustSplideHeight()
   
    },
    setSplideIndexByClass() {
  this.$nextTick(() => {
    if (this.$refs.primary && this.$refs.primary.splide) {
      const primarySlides = Array.from(this.$refs.primary.$el.querySelectorAll('li.splide__slide'));
    //  primarySlides.forEach((slide, index) => {
   //     console.log(`Primary Slide ${index}:`, slide.className, slide.innerHTML);
    //  });
      const primaryIndex = primarySlides.findIndex((li, index) => {
  return index > 1 && li.querySelector('.cyl-slide-primary'); // Anpassung der Bedingung
});
 //     console.log("Primary Index gefunden:", primaryIndex, "aus", primarySlides.length, "Slides");
      if (primaryIndex !== -1) {
   //     this.$refs.primary.splide.go(primaryIndex);
      } else {
        console.log("Kein passender Slide in Primary Splide gefunden.");
      }
    }

    if (this.$refs.secondary && this.$refs.secondary.splide) {
      const secondarySlides = Array.from(this.$refs.secondary.$el.querySelectorAll('li.splide__slide'));
 //     secondarySlides.forEach((slide, index) => {
 //       console.log(`Secondary Slide ${index}:`, slide.className, slide.innerHTML);
  //    });
      const secondaryIndex = secondarySlides.findIndex((li, index) => index > 1 && li.querySelector('.cyl-slide-secondary') && li.classList.contains('is-visible'));
   //   console.log("Secondary Index gefunden:", secondaryIndex, "aus", secondarySlides.length, "Slides");
      if (secondaryIndex !== -1) {
        this.$refs.primary.splide.go(secondaryIndex);
        this.$refs.secondary.splide.go(secondaryIndex);
   
      } else {
        console.log("Kein passender Slide in Secondary Splide gefunden.");
      }
    }
  });
},


  
    setSplideIndex() {
  console.log("setSplideIndex triggered");
 // console.log("zyklen vorhanden ", this.zyklen, "zyklen länge über 3 ", this.zyklen.length >= 3, "primary ", this.$refs.primary, "secondary ",  this.$refs.secondary, "primary index",  this.$refs.primary.index)
 // if ((this.initialLoad || this.newZyklusAdded) &&
  if (this.zyklen && this.zyklen.length >= 3 && this.$refs.primary.index < 2) {  // Überprüfung auf Anzahl der Elemente
    console.log("setSplideIndex triggered", this.zyklen.length);
    this.$nextTick(() => {
       if (this.$refs.primary && this.$refs.primary.splide) {
        console.log("grundfunktion")
        this.setSplideIndexByClass()

       }
      if (this.$refs.secondary && this.$refs.secondary.splide) {
   //     this.setSplideIndexByClass()
      //  this.$refs.secondary.splide.refresh();  // Empfohlen
      //  this.$refs.secondary.splide.go(2);
      }
    });
    this.initialLoad = false;
    this.newZyklusAdded = false;
  }
  else {
    console.log(",sdjnfjsdfdfnd")
  }
},
    syncSplider() {
      setTimeout(() => {
        if(this.$refs.primary) {
          // Set the sync for local vuex store
          
          this.$refs.primary.sync(this.$refs.secondary.splide)
          // sync splide index
          let splide = this.$refs.primary.splide
          console.log("syncSplider triggered", splide)
          if(splide) this.splideMoved(splide)
        }

        // prevent datepicker to call default method which will crash sharepoint
        let datepickerObjects = []
        let datepickerInput = document.getElementsByClassName("fv-DatePicker__input")
        let datepickerAccept = document.getElementsByClassName("fv-DatePicker__input-options-accept")
        let datepickerCancel = document.getElementsByClassName("fv-DatePicker__input-options-cancel")
        let datepickerMonth = document.getElementsByClassName("fv-DatePicker__input-month")
        let datepickerDay = document.getElementsByClassName("fv-DatePicker__input-day")
        let datepickerYear = document.getElementsByClassName("fv-DatePicker__input-year")
        datepickerObjects = Array.prototype.concat.apply(datepickerObjects, datepickerInput)
        datepickerObjects = Array.prototype.concat.apply(datepickerObjects, datepickerAccept)
        datepickerObjects = Array.prototype.concat.apply(datepickerObjects, datepickerCancel)
        datepickerObjects = Array.prototype.concat.apply(datepickerObjects, datepickerMonth)
        datepickerObjects = Array.prototype.concat.apply(datepickerObjects, datepickerDay)
        datepickerObjects = Array.prototype.concat.apply(datepickerObjects, datepickerYear)
        // add prevent default to every click event
        for (let object of datepickerObjects) {
          object.addEventListener("click", function(event) {
            event.preventDefault()
          });
        }
      }, 500)
    },
    goToCyl(id) {
  console.log("Navigating to Zyklus with ID:", id);
  if (id || id === null) {
    this.$store.commit('setZid', id);
  }

  if (!id) {
    console.log("Missing required parameters");
    return;
  }

  const routeObj = {
    name: "Zyklus",
    params: {
      sid: this.sid,
      zid: id
    }
  };

  this.$router.push(routeObj).catch(err => {
    console.log("Router push error:", err);
  });
},

goToCylEval(id) {
  if (id) {
    this.$store.commit('setZid', id);
    this.$router.push({
      name: "ZyklusEvaluation",
      params: {
        zid: id,
        colorClass: this.colorClass
      }
    })
  } else {
    console.log("error: missing id for EP")
  }
},

    colorFilter(index) {
      if (this.zyklen) {
        let length = this.zyklen.length
        let calc = (length - index) / (length + .1)
        return "filter: saturate(" + calc + ")"
      }
      return ""
    },
    addZyklus() {
      let startDate = this.$dayjs(this.newZyklus.startDate, "DD.MM.YYYY", true).format()
  let endDate = this.$dayjs(this.newZyklus.endDate, "DD.MM.YYYY", true).format()

  if (this.$dayjs(endDate).isBefore(startDate)) {
        alert("Das Enddatum befindet sich leider vor dem Startdatum, bitte überprüfen Sie ihre Eingabe.")
      } else if(startDate === "Invalid date" || endDate === "Invalid date" || this.newZyklus.klasse === null) {
        alert("Bitte füllen Sie alle Felder aus.")
      }
      else {
        // show sync status checkboxes
        this.syncSucces.addEbereiche = false
        this.syncSucces.addGoals = false
        this.syncSucces.addMeasures = false
        this.syncSucces.addZyklus = false

        // show loading, prevent duplicate request
        this.nextZyklusLoading = true
        // if there is already a zyklus
        if (Array.isArray(this.zyklen) && this.zyklen.length) {
          this.entwicklungsbereicheList = []
          this.goalList = []
          // get id of last zyklus
          let lastZid = this.zyklen[0].Id
          // get goals from last zyklus
          if(lastZid) this.$store.dispatch("loadEntZiele", lastZid)
          .then(lastGoals => {
            // load and get measures, measures only need to be reloaded when the user changes
            this.$store.dispatch("loadEntMk")
            .then(measures => {
              // consider only measures from the last cycle
              measures = measures.filter(measure => measure.RefZ === lastZid)
              // create zyklus and get new zyklus id
              this.createZyklus(zyklusId => {
                // clone measures which have been marked as reused
                this.cloneMeasures(zyklusId, measures, lastGoals)
                .then(() => {
                  // clone goals which are left
                  this.cloneGoals(zyklusId, lastGoals)
                  .then(() => {
                    // sync Splider
                    this.syncSplider()
                    // save collected entwicklungsbereiche into zyklen
                    this.cloneEntwicklungsbereiche(zyklusId);
                    // show next zyklus
                    this.nextZyklusLoading = false
                  })
                })
              })
            })
          })
        } else {
          // create the first cycle
          this.createZyklus(() => {
            this.nextZyklusLoading = false
          })
        }
        this.$refs.secondary.splide.refresh() 
        this.setSplideIndexByClass() 
      }
    },

    createZyklus(callback) {
      let zyklus = {
        Author: {
          Id: this.userId,
          Title: this.userTitle
        },
        AuthorId: this.userId,
        RefPEP: {
          Id: this.sid
        },
        RefPEPId: this.sid,
        Id: Math.floor(Math.random() * 999999999999),
        Title: this.nextZyklusTitle,
        Modified: this.$dayjs(new Date(), "DD.MM.YYYY", true).format(),
        Created: this.$dayjs(new Date(), "DD.MM.YYYY", true).format(),
        Klasse: this.newZyklus.klasse,
        StartDate: this.$dayjs(this.newZyklus.startDate, "DD.MM.YYYY", true).format(),
        OData__EndDate: this.$dayjs(this.newZyklus.endDate, "DD.MM.YYYY", true).format(),
        EB: null,
        Abschluss: false,
        Eval: false,
        PiC: [{
          Id: this.userId,
          Title: this.userTitle
        }]
      }
      this.$store.dispatch("addZyklus", zyklus)
      .then(zyklusId => {
          // check add zyklus checkbox
          this.syncSucces.addZyklus = true
          // empty fields
          this.newZyklus.klasse = ""
          this.newZyklus.startDate = new Date()
          this.newZyklus.endDate = new Date()
          // force re-render of datepicker: prevents errors for the next new cycle
          this.datePickerKey += 1
          // update updateZyklusId
          this.$emit("updateZyklusId", zyklusId)

          this.newZyklusAdded = true;  // Setzen Sie den Indikator, wenn ein neuer Zyklus hinzugefügt wird
  //  this.setSplideIndex();
          this.setSplideIndexByClass();
          // load goals with new zyklus id to be able to add elements to next zyklus
          this.$store.dispatch("loadEntZiele", zyklusId)
          .then(() => callback(zyklusId))
      })
      .catch(() => {
        alert("Es gab leider ein Problem beim Speichern des neuen Zyklus. Bitte versuchen Sie es noch einmal oder versuchen Sie die Seite neu zu laden.")
      })
      .finally(() => {
        console.log("create zyklus done, time to refresh")
        setTimeout(() => {
          console.log("wartetete")
        this.$refs.primary.splide.refresh() 
        this.$refs.secondary.splide.refresh() 
        this.setSplideIndexByClass() 
        }, 1000
        )
        this.syncSplider()
      })
    },
    cloneMeasures(zid, measures, goals) {
      if (Array.isArray(measures) && measures.length) {
        // use reduce to wait for every measure in the loop to finish
        return measures.reduce( (promise, measure) =>
        promise.then(() => {
          // clone measure from last zyklus if reuse is true
          if (measure && measure.Reuse) {
            // check if measure has a connected goal
            if (measure.RefZie && measure.RefZie.Id) {
              for (let goal of goals) {
                // clone connected goal from measure
                if (goal.Id === measure.RefZie.Id) {
                  return this.cloneGoal(zid, goal)
                  .then(newGoalId => {
                    // save goal id if the goal is not already cloned
                    if(this.goalList.filter(item => item.id === goal.Id).length === 0) {
                      this.goalList.push({
                        id: goal.Id,
                        cloneId: newGoalId
                      });
                    }
                    // clone measure which has a connected goal
                    return this.cloneMeasure(zid, measure, newGoalId, goal.Title);
                  })
                }
              }
            } else {
              // clone measure which has no connected goal
              return this.cloneMeasure(zid, measure, null);
            }
          } else return new Promise(resolve => resolve())
        })
        , Promise.resolve() );
      } else {
        // if there are no values: return a resolved promise to end cloneMeasures
        return new Promise(resolve => resolve())
      }
    },
    cloneGoal(zid, goal) {
      // prevent goals from being cloned a second time
      if (Array.isArray(this.goalList) && this.goalList.length) {
        for (let g of this.goalList) {
          if (g.id === goal.Id) {
            // stop function and return id from already cloned goal
            return new Promise(resolve => resolve(g.cloneId));
          }
        }
      }

      // get entwicklungsbereich from goal which will be saved later into zyklus, prevent doubles
      if (!this.entwicklungsbereicheList.includes(goal.EBereiche)) this.entwicklungsbereicheList.push(goal.EBereiche)

      let clonedGoal =  {
        Id: this.$store.getters.nextEntZielId,
        RefZ: zid,
        Title: goal.Title,
        IstStand: goal.IstStand,
        Entwicklungsziel:  goal.Entwicklungsziel,
        EBereiche: goal.EBereiche,
        EvalObj: null,
        Reuse: false
      }
      // check add goals checkbox
      this.syncSucces.addGoals = true
      return this.$store.dispatch("addEntZiel", clonedGoal)
    },
    cloneMeasure(zid, measure, goalRefId, goalRefTitle) {
      // create measure item with new goal id from created goal
      let clonedMeasure = {
        Id: this.$store.getters.nextEntMkiId,
        RefZ: zid,
        Title: measure.Title,
        RefMeth: {
          Id: measure.RefMeth.Id,
          Title: measure.RefMeth.Title,
        },
        RefMethId: measure.RefMeth.Id,
        RefMethZiel: measure.RefMethZiel,
        RefMethBeschr: measure.RefMethBeschr,
        Zeitumfang: measure.Zeitumfang,
        Sozialform: measure.Sozialform,
        Voraussetzungen_x002f_Materialie: measure.Voraussetzungen_x002f_Materialie,
        Kurzbeschreibung: measure.Kurzbeschreibung,
        Evaluationskriterien_x0020__x002: measure.Evaluationskriterien_x0020__x002,
        Evaluationskriterien_x0020__x0020: measure.Evaluationskriterien_x0020__x0020,
        RefZie: goalRefId ? {Id: goalRefId, Title: goalRefTitle} : null,
        EvalObj: null,
        Reuse: false
      }
      // check add measures checkbox
      this.syncSucces.addMeasures = true
      return this.$store.dispatch("addEntMki", clonedMeasure)
    },
    cloneGoals(zid, goals) {
      if (Array.isArray(goals) && goals.length) {
        // use reduce to wait for every item in the loop to finish
        return goals.reduce( (promise, goal) =>
          promise.then(() => {
            if (goal.Reuse) {
              return this.cloneGoal(zid, goal)
            } else return new Promise(resolve => resolve())
          })
        , Promise.resolve() );
      } else {
        // if there are no values: return a resolved promise to end cloneGoals
        return new Promise(resolve => resolve())
      }
    },
    cloneEntwicklungsbereiche(zid) {
      // load new zyklus to be able to add eb
      this.$store.dispatch("loadZyklen", this.sid)
      .then(() => {
        // check add ebereiche checkbox
        this.syncSucces.addEbereiche = true
        // add cloned development areas to new zyklus
        for(let eb of this.entwicklungsbereicheList) {
          this.$store.commit("addZyklusEb", {
            id: this.sid,
            zid: zid,
            eb: eb
          })
        }
      })
    },
     deleteCycl() {

         if(window.confirm("Sind Sie sicher, dass Sie diesen Zyklus endgültig löschen möchten?")) {
         let  id = this.latestZyklus.Id
         console.log(id)
            this.$store.dispatch("deleteCycl", id)

       } else {
         alert("Um diesen Zyklus zu löschen, bestätigen Sie dies bitte.")
       }
     },
     adjustSplideHeight() {
  let splideList = null;

  // Überprüfen, ob der spezielle Ref 'primary' existiert
  if (this.$refs.primary) {
    splideList = this.$refs.primary.$el.querySelector(".splide__list");
  }

  // Überprüfen, ob 'alleZyklen' Ref existiert
  if (this.$refs.alleZyklen && splideList) {
    const classList = this.$refs.alleZyklen.$el.classList;

    // Überprüfen, ob 'is-active' und 'is-visible' in den Klassen vorhanden sind
    if (classList.contains('is-active') && classList.contains('is-visible')) {
      // Füge die Klasse 'height-150' hinzu
      splideList.classList.add('height-150');
    } else {
      // Entferne die Klasse 'height-150'
      splideList.classList.remove('height-150');
    }
  }
}


}

  
}
</script>
