<style>
:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-color2: 210deg 100% 20%;
  --shadow-elevation-low:
    -0.8px 0.7px 1.2px hsl(var(--shadow-color) / 0.16),
    -1.5px 1.3px 2.2px -1.2px hsl(var(--shadow-color) / 0.16),
    -3.9px 3.4px 5.8px -2.5px hsl(var(--shadow-color) / 0.16);
  --shadow-elevation-medium:
    -0.8px 0.7px 1.2px hsl(var(--shadow-color) / 0.13),
    -2.4px 2.1px 3.6px -0.6px hsl(var(--shadow-color) / 0.13),
    -4.9px 4.3px 7.3px -1.2px hsl(var(--shadow-color) / 0.13),
    -10.1px 8.7px 15px -1.9px hsl(var(--shadow-color) / 0.13),
    -19.7px 17px 29.3px -2.5px hsl(var(--shadow-color) / 0.13);
  --shadow-elevation-high:
    -0.8px 0.7px 1.2px hsl(var(--shadow-color) / 0.14),
    -5.8px 5px 8.6px -0.3px hsl(var(--shadow-color) / 0.14),
    -10.9px 9.4px 16.2px -0.6px hsl(var(--shadow-color) / 0.14),
    -17.3px 14.9px 25.7px -0.9px hsl(var(--shadow-color) / 0.14),
    -26.4px 22.9px 39.3px -1.2px hsl(var(--shadow-color) / 0.14),
    -39.7px 34.3px 59px -1.6px hsl(var(--shadow-color) / 0.14),
    -58.5px 50.6px 87px -1.9px hsl(var(--shadow-color) / 0.14),
    -84.1px 72.7px 125.1px -2.2px hsl(var(--shadow-color) / 0.14),
    -118px 102.1px 175.5px -2.5px hsl(var(--shadow-color) / 0.14);
    --mirror-elevation-high:
    1px 0px 1.3px hsl(var(--shadow-color) / 0.08),
    8.7px -0.2px 11px -0.2px hsl(var(--shadow-color) / 0.09),
    15.8px -0.4px 19.9px -0.4px hsl(var(--shadow-color) / 0.1),
    23.4px -0.6px 29.5px -0.5px hsl(var(--shadow-color) / 0.1),
    32.8px -0.8px 41.3px -0.7px hsl(var(--shadow-color) / 0.11),
    45.3px -1.1px 57.1px -0.9px hsl(var(--shadow-color) / 0.12),
    62px -1.5px 78.1px -1.1px hsl(var(--shadow-color) / 0.13),
    84.2px -2px 106.1px -1.2px hsl(var(--shadow-color) / 0.14),
    113.2px -2.7px 142.7px -1.4px hsl(var(--shadow-color) / 0.15),
    150px -3.6px 189.1px -1.6px hsl(var(--shadow-color) / 0.16);
    --bg-MKTg: #dba82c;
    --bg-MTKi: #c3d79b;
    --bg-MKi: #7dbbf9; 
}
.workspace-area-mini {
  z-index: 0;
  transition: 0.3s padding-left;
  opacity: 1;
  min-height: 60%;
  width: calc(100vw - 52px - (100vw - 100%));
  overflow-y: hidden;
  display: grid;
  /* grid-auto-rows: minmax(400px, auto) minmax(200px, max-content) auto; */
  top: 0;
  transition: width 0.3s;
  position: initial;
  /* background-color: rgba(255, 255, 255, 0.878); */
  overflow-x: hidden;
  background-repeat: no-repeat;
background-size: cover;
transition:  height .3s ease 1s, width 0.3s, opacity .3s;
}

.workspace-area-mini.collapsed {
  width: calc(100vw - 52px - (100vw - 100%));
  margin-left: 50px;
  transition: 0.3s width;
  transition: 0.3s margin-left;
}


.stats-area {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 33px 0 33px;
}

.card-group {
  display: grid;
  grid-auto-columns: auto auto auto;
  grid-auto-rows: auto;
  grid-auto-flow: column;
  place-content: space-around;
  grid-auto-columns: minmax(0, 0.2fr);
}


.own-action {
  background: #7dbbf9;
}

.own-template-action {
  background: #c3d79b;
}

.group-template-action {
  background: #dba82c;
}



.fv-light-CommandBar .command-bar-list-view-container {
  z-index: 9;
}


.actions-command-bar-search-input {
  margin: 5px 0px 5px 5px !important;
  padding: 5px !important;
  padding-right: 35px !important;
  background: inherit;
  outline: none;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 30, 0.36) !important;
  width: 300px;
}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.kommup-enter-active,
.kommup-leave-active {
  opacity: 1;
}

.kommup-enter-from,
.kommup-leave-to {
  opacity: 0;
}

</style>


<style scoped>
.primary-selection {
  display: grid;
  grid-auto-flow: column;
  height: auto;
  font-family: 'aileronthin';
  max-height: 45vh;
}
.left-container {
  background-color: rgba(255,227,126,.7);
  position: relative;
}
.right-container {
  background-color: rgba(108,180,210,.7);
  position: relative;
}
.left-container span, .right-container span {
  top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%,-50%);
    position: absolute;
    width: 50%;
font-size: 250%;
line-height: 150%;
font-weight: 900;
}
.left-container:hover , .right-container:hover  { backdrop-filter: blur(5px);
  z-index: 1;
  transition: all .25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  cursor: pointer;
 }
 .left-container:hover > span, .right-container:hover > span {
  font-size: 260%;
  transition: all .25s cubic-bezier(0.68, -0.55, 0.27, 1.55);
 }
.left-container:hover {
  box-shadow: var(--mirror-elevation-high);
}
.right-container:hover  {
  box-shadow: var(--shadow-elevation-high);
}
.actionsdetails-modal {
  position: absolute;
  height: 100vh;
  width: 100%;

}

.small {
  z-index: -1;
  height: 0px
}
.large {
  position: fixed;
  top: 0;
    left: 50px;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(12.6px); 
    -webkit-backdrop-filter: blur(12.6px);
    width: calc(100vw - 50px);
/* left: -33px; */

}

.content-area {
  padding: 33px 33px 33px 33px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  height: auto;
  background-color: rgba(108,180,210,.7);
  backdrop-filter: blur(12.9px);
  margin: 0;
opacity: 1;
  height: 100%;
  width: 100%;
  position: relative;
  transition: all 1s ease;
  grid-auto-rows: 0 auto;

}
.normalBG {
  background-image: none !important;
  min-height: 30vh;
  grid-auto-rows: auto 0;
}
.contentBG {
  max-height: 99vh;
  min-height: 90vh;
  grid-auto-rows: 0 auto;
}
.back-area {
  top: 0;
  left: 0;
  width: calc(100% - 56px);
  height: 48px;
  background: #ffffff70;
position: absolute;
backdrop-filter: blur(5px);
box-shadow: var(--shadow-elevation-low);
padding: 0px 28px;
cursor: pointer;
}
.back-area:hover {
  box-shadow: var(--shadow-elevation-medium);
  background: #ffffffc9;
  transition: all .2s ease;
}
.back-button {
  padding-top: 10px;
}
.back-button i {
  font-size: 70%;
}

.actions-command-bar {
  height: 70px;
  align-content: center;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  place-items: center;
  margin-top: .5%
}

:deep(.fv-light-CommandBar .command-bar-list-view-container) {
  width: 300px !important
}

:deep(.item-content > .name), 
:deep(.item-content > i) {
  font-size: 16px !important
}
:deep(.fv-TreeView--children > li > div) {
  background: #f0efef !important;
}
.hideBystander {
width: 0px;
transition: 3s ease 
}
.hideAnimation {
animation: hideAndMorph 3s forwards ease;
height: 83%;
overflow: hidden;
}
@keyframes hideAndMorph {
  from {
    width: 50%
  }
  to {
    width: 100%;
  }
}
.hide {
  opacity: 0;
  transition: opacity .5s ease-in-out .2s;
  z-index: -1;

}

.show-grid {
  opacity: 1;
  transition: opacity 1.5s ease-in-out 1s;
  display: grid;
}
.show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  display: block;
}
.mini-bg {
  background-color: #ffffffa8;
  position: absolute;
  width: calc(100% - 66px);
  height: calc(98% - 66px - 70px - 35px);
overflow: hidden;
}
.mini-bg > ul {
  border-bottom-width: 2px;
border-bottom-style: solid;
overflow-y: scroll;
}
.table-legend {
  display: grid;
grid-auto-flow: column;
margin-top: 2%;
background: hsla(0,0%,93.7%,.6);
padding: 8px;
place-content: start;
grid-gap: 5%;
grid-auto-columns: auto max-content;
}
.legend-item {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5%;
  white-space: nowrap;
font-size: 80%;
}
.legend-icon{
  margin-right: 5%;
}
.legend-icon > i::before {
  bottom: 19%;
  height: 66%;
}
.legend-label {
  font-family: 'aileronsemibold';
letter-spacing: normal;
}
</style>
<template>
  <div>
  <div id="wsa" class="workspace-area-mini" ref="workspace" :class="selectedClass" 
     :style="{ width: conditionalWidth, height: conditionalHeight, backgroundImage: !showPrimarySelection ? `url(${this.backgroundImage})` : 'none' }">

   
<div class="primary-selection" ref="prime" :style="{ backgroundImage: `url(${this.backgroundImage}` }">
<div class="left-container" @click="createNewAction" ref="leftContainer" >
  <span>Neue Maßnahme anlegen</span>
</div>
<div  class="right-container"  @click="animateBox2" ref="rightCont" >
  <span>Vorhandene Maßnahme verwenden</span>
</div>
</div>
    <div class="content-area hide" v-bind:style="{ visibility: contentAreaVisibility }"  ref="contentArea">
      <div class="back-area" @click="backanimate">
        <div class="back-button">
          <i :class="`ms-Icon ms-Icon--ChevronLeft`"></i>
          <span>Zurück</span>
        </div>
      </div>
      <div class="table-legend">
        <div class="legend-item">
          <span class="legend-icon"><i data-v-2a814e08="" aria-hidden="true" class="ms-Icon ms-Icon--ViewDashboard group-template-action"></i></span>
          <span class="legend-label">Schulkatalog</span>
        </div>
        <div class="legend-item">
          <span class="legend-icon"><i data-v-2a814e08="" aria-hidden="true" class="ms-Icon ms-Icon--ViewDashboard own-template-action"></i></span>
          <span class="legend-label">eigene Vorlage</span>
        </div>
        <div class="legend-item">
          <span class="legend-icon"><i data-v-2a814e08="" aria-hidden="true" class="ms-Icon ms-Icon--ViewDashboard own-action"></i></span>
          <span class="legend-label">personalisierte Maßnahme</span>
        </div>
      </div>
      <fv-CommandBar class="actions-command-bar" :options="commandbaroptions">
        <template v-if="searchbar" v-slot:right-space>
          <div class="actions-command-bar-search">
            <input id="searchBar" class="actions-command-bar-search-input" type="text" @keyup.enter.prevent @keydown.enter.prevent
              v-model="searchText" placeholder="Suche" />
            <i class="material-icons actions-command-bar-close" @click.stop.prevent="hideSearchbar"
              style="vertical-align: middle;">close</i>
          </div>
        

        </template>
      </fv-CommandBar>
      <transition name="kommup-in" class="ep" :duration="1000" appear v-if="showActions">
      <div v-if="hideTable">
        <table-skeleton v-if="masznahmen.length === 0" />
<ActionsTree :masznahmen="this.masznahmen" 
:masznahmentemplateI="this.masznahmentemplateI" 
:masznahmentemplateG="this.masznahmentemplateG" 
:searchText="searchText"  
:mkiVisible="mkiVisible"
:mtkiVisible="mtkiVisible"
:mtkgVisible="mtkgVisible"
@setaction="setactiondetails2"
:pagination="true"
:Height="'80%'"
:isMobile="isMobile"
class="mini-bg"
:masznahmenUpdatedAt="masznahmenUpdatedAt"
:sortOrder="sortOrder" 
ref="actionTree"
v-else
></ActionsTree>
</div>

<div v-else style="height: 90%">
  <table-skeleton v-if="masznahmen.length === 0" />

      <ActionsTable  v-else :masznahmen="this.masznahmen" 
:masznahmentemplateI="this.masznahmentemplateI" 
:masznahmentemplateG="this.masznahmentemplateG" 
:searchText="searchText"  
:mkiVisible="mkiVisible"
:mtkiVisible="mtkiVisible"
:mtkgVisible="mtkgVisible"
:head="masznahmenhead" :compact="false" @setaction="setactiondetails"
:masznahmenUpdatedAt="masznahmenUpdatedAt"
>
      </ActionsTable>

    </div>
  </transition >
      <hr style="width: calc(99% - 20px)" />
    </div>

    
    <div :class="modalVisible ? 'large' : 'small'" class="actionsdetails-modal" @close="closeModal"
      v-shared-element:namespace ref="to">
      <router-view @maßnahmeCRUD="handleMaßnahmeCRUD"></router-view>
    </div>
  
  </div>
  <ActionCardSkeleton v-if="masznahmenLoading"></ActionCardSkeleton>
  <ActionsCardSet :cards="this.cards" @setactionDetails="setactiondetailsCard" ></ActionsCardSet>
</div>
</template>

<script>
import ActionsTree from "../Reusable/ActionsTree.vue"
import ActionsTable from "../Reusable/ActionsTable.vue";
import TableSkeleton from "../Reusable/TableSkeleton.vue";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import { illusory } from 'illusory' 
import ActionsCardSet from "../Cards/ActionsCardSet.vue";
import ActionCardSkeleton from '../Reusable/ActionCardSkeleton.vue'



const EIGENE_MASSNAHMEN_INDEX = 0;
const EIGENE_VORLAGEN_INDEX = 2;
const SCHULKATALOG_INDEX = 4;

export default {
  name: "ZyklusActionsMiniDashboard",
  props: {
  },
  data() {
    return {
masznahmenTree: [],
sortOrder: ['Standard','Title', 'Lernumgebung', 'Altersgruppe', 'Zeitumfang', 'Sozialform', 'Änderungsdatum'],
showPrimarySelection: true,
selectedClass: 'normalBG',
showActions: false,
contentAreaVisibility: 'visible',
masznahmenUpdatedAt: new Date(),
hideBox: false,
    mkiVisible: true,
    mtkiVisible: true,
    mtkgVisible: true,
    searchText: "",
      backgroundImage: require(`../../../assets/3D-2.jpg`),
      masznahmenhead: [
        "Typ",
        "Titel // Methode",
        "Lernumgebung // Altersgruppe",
        "Zeitumfang // Sozialform",
        "Aktionen",
      ],
      itemselected: null,
      modalVisible: false,
      searchbar: false,
      commandbaroptions: [{
        name: "Filter",
        icon: "Filter",
        iconColor: "rgba(0, 120, 212, 1)",
        secondary: [{
          name: "personalisierte Maßnahme",
          icon: "ViewDashboard",
          iconColor: "rgba(0, 153, 204, 1)",
          func: this.filterMki
        }, {
          type: "divider"
        }, {
          name: "eigene Vorlagen",
          icon: "ViewDashboard",
          iconColor: "rgba(0, 153, 204, 1)",
          func: this.filterMTki
        },
        {
          type: "divider"
        }, {
          name: "Schulkatalog",
          icon: "ViewDashboard",
          iconColor: "rgba(0, 153, 204, 1)",
          func: this.filterMTkg
        },]
      },
      {
        name: "Ansicht",
        icon: "RedEye",
        iconColor: "rgba(0, 120, 212, 1)",
        secondary: [{
          name: "Gruppiert",
          icon: "GroupList",
          iconColor: "rgba(0, 120, 212, 1)",
          func: this.showTree,
        }, {
          type: "divider"
        }, {
          name: "Liste",
          icon: "BulletedList",
          iconColor: "rgba(0, 120, 212, 1)",
          func: this.showList,
        },]
      },
      {
        name: "Sortieren",
        icon: "Sort",
        iconColor: "rgba(0, 120, 212, 1)",
        secondary: [
        {
          name: "Standard",
          icon: "ViewOriginal",
          choosen: true, 
          iconColor: "rgba(0, 120, 212, 1)",
          func: () => this.sortData('Standard'),
        }, {
          type: "divider"
        },
        {
          name: "Änderungsdatum",
          icon: "DateTime",
          choosen: false, 
          iconColor: "rgba(0, 120, 212, 1)",
          func: () => this.sortData('Änderungsdatum'),
        }, {
          type: "divider"
        }, {
          name: "Bezeichnung",
          icon: "HalfAlpha",
          choosen: false, 
          iconColor: "rgba(0, 120, 212, 1)",
          func: () => this.sortData('Bezeichnung'),
        },
        {
          type: "divider"
        }, {
          name: "Lernumgebung",
          icon: "ArrangeBringToFront",
          choosen: false, 
          iconColor: "rgba(0, 120, 212, 1)",
          func: () => this.sortData('Lernumgebung'),
        },
        {
          type: "divider"
        }, {
          name: "Altersgruppe",
          icon: "PeopleAdd",
          choosen: false, 
          iconColor: "rgba(0, 120, 212, 1)",
          func: () => this.sortData('Altersgruppe'),
        },
        {
          type: "divider"
        }, {
          name: "Zeitumfang",
          icon: "DateTime2",
          choosen: false, 
          iconColor: "rgba(0, 120, 212, 1)",
          func: () => this.sortData('Zeitumfang'),
        },
        {
          type: "divider"
        }, {
          name: "Sozialform",
          icon: "PeopleAdd",
          choosen: false, 
          iconColor: "rgba(0, 120, 212, 1)",
          func: () => this.sortData('Sozialform'),
        },]
      },
      {
        name: "Suche",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar
      }],
      hideTable: true
        };
  },
  watch: {
    modalVisible() {
      if (this.modalVisible === false) {
        window.removeEventListener("keyup", this.onEscapeKeyUp);
       
      } else {

        window.addEventListener("keyup", this.onEscapeKeyUp);
       
      }
      this.scrollToTop();
    },

     $route: {
      immediate: true,
     // handler: function (newVal) {
    //   //   this.modalVisible = newVal.meta && newVal.meta.modalVisible;
    //   // },
     handler(to) {
          this.modalVisible = to.meta && to.meta.modalVisible;
           this.scrollToTop();
       }
 },
    mkiVisible: {
      deep: true,
      handler() {
        this.toogleText('personalisierte Maßnahmen', '̶̶p̶e̶r̶s̶o̶n̶a̶l̶i̶s̶i̶e̶r̶t̶e̶ ̶M̶a̶ß̶n̶a̶h̶m̶e̶n̶', EIGENE_MASSNAHMEN_INDEX, this.mkiVisible)
   
      },
    },

    mtkiVisible: {
      deep: true,
      handler() {
        this.toogleText('eigene Vorlage', '̶e̶i̶g̶e̶n̶e̶ ̶V̶o̶r̶l̶a̶g̶e̶', EIGENE_VORLAGEN_INDEX, this.mtkiVisible)
     
      },
    },

    mtkgVisible: {
      deep: true,
      handler() {
        this.toogleText('Schulkatalog', '̶̶̶S̶c̶h̶u̶l̶k̶a̶t̶a̶l̶o̶g̶', SCHULKATALOG_INDEX, this.mtkgVisible)
       
      },
    },

  },
  

  methods: {
    sortData(sortOptionName) {
  // Alle 'choosen' Flags zurücksetzen, aber nur für tatsächliche Sortieroptionen
  this.commandbaroptions
    .find(option => option.name === 'Sortieren').secondary
    .forEach(option => {
      if (option.type !== 'divider') {
        option.choosen = false;
      }
    });

  if (sortOptionName === 'Standard') {
    // Zurücksetzen auf Standard-Sortierung
    this.sortOrder = ['Standard'];
    this.$refs.actionTree.isCustomSortActive = false;
    // Setzen des 'choosen' Flags für die Standardoption
    this.commandbaroptions
      .find(option => option.name === 'Sortieren').secondary
      .find(option => option.name === 'Standard').choosen = true;
  } else {
    // Benutzerdefinierte Sortierung
    this.sortOrder = [sortOptionName];
    this.$refs.actionTree.isCustomSortActive = true;
    // Setzen des 'choosen' Flags für die gewählte Option
    this.commandbaroptions
      .find(option => option.name === 'Sortieren').secondary
      .find(option => option.name === sortOptionName).choosen = true;
  }

  // Trigger der Aktualisierung der Ansicht im ActionsTree
//  this.$refs.actionTree.initializeTree();
},
    showTree() {
      this.hideTable = true
    },
    showList() {
      this.hideTable = false
    },
    handleMaßnahmeCRUD() {
  this.masznahmenUpdatedAt = new Date();
},
    showActionList() {
this.showPrimarySelection = false
    },
    changeClassAfterDelay() {
      setTimeout(() => {
        this.selectedClass = !this.showPrimarySelection ? 'contentBG' : 'normalBG';
      }, 1000);  // 5000 Millisekunden = 5 Sekunden
    },

     animateBox2() {
      const start = this.$refs.rightCont
     const end = this.$refs.contentArea
     illusory(start, end, {
      async beforeAnimate(from, ) {
        // Show the natural element and hide the clone
        // because by default the clone has already
        // been replaced the natural element
        from.showNatural()
        from.hide()

        // Set the clone to animate opacity
        from.setStyle('transition', 'opacity 0.3s')
        // Force the style changes to be rendered
        from.flushCSS()

        // Show the clone and wait for it fade in
        from.show()
        await from.waitFor('opacity')

        from.hideNatural()
    },
    beforeDetach(from, to) {
        from.hide()

        to.showNatural()

        to.setStyle('transition', 'opacity 0.1s')
        to.hide()

        return to.waitFor('opacity')
    }
          
  })
  this.$refs.prime.classList.remove('show-grid')
       this.$refs.prime.classList.add('hide')
       this.$refs.contentArea.classList.remove('hide')
     this.showActions = true
     this.showPrimarySelection = false
     this.scrollTo("wsa")
     this.changeClassAfterDelay();
   
    },
    backanimate() {
      const end = this.$refs.rightCont
     const start = this.$refs.contentArea
     console.log(end, start)
      illusory(start, end, {
        async beforeAnimate(from, ) {
        // Show the natural element and hide the clone
        // because by default the clone has already
        // been replaced the natural element
        from.showNatural()
        from.hide()
        
        // Set the clone to animate opacity
        from.setStyle('transition', 'opacity .1s')
        from.setStyle('height', '50%')
        // Force the style changes to be rendered
        from.flushCSS()

        // Show the clone and wait for it fade in
        from.show()
        await from.waitFor('opacity')

    },
    beforeDetach(from, to) {
  
   to.setStyle('transition', 'opacity .1s')
        to.hide()

        return to.waitFor('opacity')
    }
     
    })
      
        this.$refs.contentArea.classList.add('hide')
        this.$refs.prime.classList.remove('hide')
        this.$refs.prime.classList.add('show-grid')
        this.showPrimarySelection = true
        this.showActions = false
        this.changeClassAfterDelay();
        this.scrollTo("MN")
    }
  
    ,
    backToPrimary() {
      this.showPrimarySelection = true
    },
    filterMTkg() {
      this.mtkgVisible = !this.mtkgVisible
      //  this.updateTarget(event.target, this.mtkgVisible)
    },
    filterMTki() {
      this.mtkiVisible = !this.mtkiVisible
      //     this.updateTarget(event.target, this.mtkiVisible)
    },
    filterMki() {
      this.mkiVisible = !this.mkiVisible
      //   this.updateTarget(event.target, this.mkiVisible)
    },
    toogleText(normal, filtered, i, data) {
      let val = data ? normal : filtered
      this.commandbaroptions[0].secondary[i].name = val
    },
    setactiondetails([payload, target]) {
      if (payload) {
        this.modalVisible = true;
        this.morph(payload[0].Id, target)
        return (this.itemselected = payload);
      } else return (this.itemselected = null);
    },
    setactiondetails2([data, payload, target]) {
      if (data, payload) {
        this.modalVisible = true;
      this.morph(payload.Id, [ payload.Type, target])
        return (this.itemselected = payload);
      } else return (this.itemselected = null);
    },
    setactiondetailsCard(data) {
      if (data) {
        this.modalVisible = true;
        this.morph(data.payload.Id, [ 'MKi', data.clickedCard])
        return (this.itemselected = data.payload);
      } else return (this.itemselected = null);
    
    
    },

    onEscapeKeyUp(event) {
      if (event.key === "Escape") {
        this.closeModalAndNavigate();
      }
    },
    closeModal() {
      this.modalVisible = false;
   //   this.morphback(this.$route.params.ActionsDetailsId);
    
    },
    morph(Id, [type, target]) {
      this.$illusory(target, this.$refs.to, {
        relativeTo: [],
        duration: '300ms',
        element: {
          includeChildren: false
        }
      });
let studentId = this.$route.params.sid
let zyklusId = this.$route.params.zid
      // this.$router.push({ path: `/ActionsDashboard/${Id + type}`,  query: { typ: type }  });
      this.$router.push({ name: 'Maßnahme', params: { sid: studentId, zid: zyklusId, ActionsDetailsId: `${Id}_${type}`,  mid: `${Id}_${type}`, isTemplate: true } });
           // this.$router.push({ name: 'ZyklusMaßnahme', path: `ZActionsDetailsId/${Id + '_' +  type}`  });
    },
    morphback(Id) {
      this.$illusory(this.$refs.to, this.$refs[Id], {
        relativeTo: [],
        element: {
          includeChildren: false
        },
        duration: '300ms',
      });
    },
    shouldScrollToTop() {
      return this.modalVisible 
    },
    scrollTo(hash) {
      let element = document.getElementById(hash)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }
    },
    scrollToTop() {
  if (this.shouldScrollToTop()) {
    // window does not work in sharepoint
    if (typeof _spPageContextInfo !== "undefined") {

      const workspace = document.getElementById("s4-workspace");
      if (workspace) {
    //    workspace.scrollTo(0, 0);
    //this.scrollTo("wsa")
    let element = document.getElementById('wsa')
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
        workspace.style.overflow = "hidden";
      }
    } else {
  //    this.scrollTo("wsa")
    //  window.scrollTo(0, 0);
    let element = document.getElementById('wsa')
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
      document.body.style.overflow = "hidden";
    }
  } else {
    if (typeof _spPageContextInfo !== "undefined") {
      const workspace = document.getElementById("s4-workspace");
      if (workspace) {
        workspace.style.overflow = "auto";
      }
    } else {
     
      document.body.style.overflow = "auto";
    }
  }
},
    showSearchbar() {
      this.searchbar = true
      this.commandbaroptions.pop()
      // wait until searchbar is rendered
      this.$nextTick(function () {
        document.getElementById("searchBar").focus()
        document.getElementById("searchBar").select()
      })

    },
    hideSearchbar() {
      this.searchText = ""
      this.searchbar = false
      this.commandbaroptions.push({
        name: "Suche",
        icon: "Search",
        iconColor: "rgba(0, 120, 212, 1)",
        func: this.showSearchbar
      })

    },
    createowntemplate() {
      this.$router.push({ name: `NewActionZ`, params: { type: 'eigene Vorlage' } });
    },
    creategrouptemplate() {
      this.$router.push({ name: `NewActionZ`, params: { type: 'Schulkatalog' } });
    },
    createNewAction() {
   
      this.$router.push({ name: `NewActionZ`, params: { type: 'personalisierte Maßnahme' } });
      
      
   //   this.scrollTo("wsa")
    },
    closeModalAndNavigate() {
      if(this.modalVisible){
      this.$emit("close");
    //  this.morphback(this.$route.params.ActionsDetailsId);
      this.modalVisible = false;
      this.$router.go(-1);
    }},
   
  },
  beforeRouteLeave(to, from, next) {
  // Nur ausführen, wenn die Route, zu der wir navigieren, 'NewAction' oder 'Maßnahme' ist
  if (to.name === 'NewActionZ' || to.name === 'Maßnahme') {
    let element = document.getElementById('wsa')
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
  // Vergessen Sie nicht, next() aufzurufen, um die Navigation fortzusetzen
  next();
}
,
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    conditionalWidth() {
      if (this.isMobile) {
        return "calc(100vw - (100vw - 100%) - 0px)";
      } else return "calc(100vw - (100vw - 100%) - 0px)";
    },
    conditionalHeight()  {
      if (this.modalVisible) {
        return "100vh"
      }
      else return ""
    },
    masznahmen() {
      let masznahmendata = this.$store.getters.entMk;
      if (Array.isArray(masznahmendata)) {
        return masznahmendata.map(v => ({ ...v, label: v.Title }));
      } else return [];
    },
    cards() {
  let zid = this.$route.params.zid;
  if (zid) {
    return this.$store.getters.entMkbyZid(Number.parseInt(zid, 10));
  }
  return [];
},
masznahmenLoading() {
      return this.$store.getters.entMkLoading;
    },
    masznahmentemplateI() {
      let mtki = this.$store.getters.entMTKi;
      if (Array.isArray(mtki)) {
        return mtki.map(v => ({ ...v, label: v.Title }));
      } else return [];
    },
    masznahmentemplateG() {
      let mtkg = this.$store.getters.entMTKg;
      if (Array.isArray(mtkg)) {
        return mtkg.map(v => ({ ...v, label: v.Title }));
      } else return [];
    },
    masznahmencounter() {
      if (Array.isArray(this.masznahmen) && this.masznahmen.length) {
        return this.masznahmen.length;
      } else return 0;
    },
    mtkiCounter() {
      if (Array.isArray(this.masznahmentemplateI) && this.masznahmentemplateI.length) {
        return this.masznahmentemplateI.length;
      } else return 0;
    },
    mtkgCounter() {
      if (Array.isArray(this.masznahmentemplateG) && this.masznahmentemplateG.length) {
        return this.masznahmentemplateG.length;
      } else return 0;
    },
  },
 
  components: {
    TableSkeleton,
    ActionsTable,
    ActionsTree,
ActionsCardSet,
ActionCardSkeleton
  },
};
</script>
