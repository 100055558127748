<template>
<div class="container">
  <div
    v-bind:class="[SortBannerClass]"
    v-on:click="!start ? { click: showInfo() } : null"
  >
    <div class="LeftiFirstIntroContainer">
      <span
        class="IntroHeading"
        :class="{
          'slide-up-fade-in': this.start,
        }"
        >Zuordnung der erstellten Elemente</span
      >
      <span class="closehelper" v-if="!this.start && !this.showInfos"
        >X</span
      >
      <span class="IntroDescription slide-up-fade-in">
        <br />
        Die erstellten Entwicklungsziele und -maßnahmen sollten nicht
        isoliert, sondern vielmehr in ihrer gegenseitigen Beziehung
        betrachtet werden. Dafür ist es nötig, dass Sie die erstellten
        Elemente abschließend zuordnen.
        <br /><br />
        Weisen Sie nun den Entwicklungsbereich(en) die entsprechenden
        Entwicklungsziele zu. Gehen Sie bei der Zuweisung der
        Entwicklungsmaßnahme(n) zu den zugehörigen Entwicklungsziel(en)
        analog vor.
        <br /><br />
        Klicken Sie dazu auf die entsprechende Karte in der Auswahl und
        ziehen Sie diese in einen der freien Felder in der Übersicht.
        Sollten Sie ein Tablet nutzen, können Sie die Karte durch
        Fingergestik ziehen.
        <br /><br />
      </span>
    </div>
  </div>
  <div class="CardFormContainer GeneralCard">
    <div class="arrangeBox">
      <object type="image/svg+xml" class="svgimg" :data="meinsvg"></object>
      <div class="bannerContainerZ" :class="{'bannerContainerZ--gradient': !this.start}">
        <div v-bind:class="[StashBannerClass]">
          <div class="LeftiSecondIntroContainer">
            <div class="intro-heading-container">
              <span
                class="IntroHeading"
                :class="{
                  'slide-up-fade-in': this.start,
                }">
                Elementauswahl
              </span>
              <helper-icon-callout
                :Title="''"
                :Main="'Klicken Sie auf die entsprechende Ziel- oder Maßnahmen-Karte in der Auswahl und ordnen Sie diese den Entwicklungsbereichen zu. Sollten Sie ein Tablet nutzen, können Sie die Karte durch Fingergestik ziehen.'">
              </helper-icon-callout>
            </div>
            <div class="elementstash">
              <div class="stashcontainer">
                <div class="stasheading">
                  <span>Entwicklungsziele</span>
                </div>
                <div class="goalstash">
                  <vue-nestable
                    v-model="EZClone"
                    cross-list
                    group="cross"
                    class-prop="class"
                    treshold="100"
                    @change="saveZuordnung"
                  >
                    <div
                      slot="placeholder"
                      class="placeholderlist"
                      style="text-align: center"
                    >
                      <b>Alle Entwicklungsziele wurden zugeordnet</b>
                    </div>
                    <vue-nestable-handle
                      slot-scope="{ item, index }"
                      class=""
                      :item="item"
                      :id="'Area' + index"
                    >
                      <div class="sortlabel">{{item.label}}</div>
                      <div class="sortheader">
                        {{ item.name }}
                      </div>
                    </vue-nestable-handle>
                  </vue-nestable>
                </div>
              </div>

              <div class="stashcontainer">
                <div class="stasheading">
                  <span>Entwicklungsmaßnahmen</span>
                </div>
                <div class="actionstash">
                  <vue-nestable
                    v-model="EMClone"
                    cross-list2
                    group="cross2"
                    class-prop="class"
                    treshold="30"
                  >
                    <div
                      slot="placeholder"
                      class="placeholderlist"
                      style="text-align: center; border-radius: 0px"
                    >
                      <b>Alle Entwicklungsmaßnahmen wurden zugeordnet</b>
                    </div>
                    <vue-nestable-handle
                      slot-scope="{ item, index }"
                      class=""
                      :item="item"
                      :id="'Area' + index"
                    >
                      <div class="sortlabel">{{item.label}}</div>
                      <div class="sortheader">
                        {{ item.name }}
                      </div>
                    </vue-nestable-handle>
                  </vue-nestable>
                </div>
              </div>
              <div
                class="buttonarea"
              >
                <GeneralCardActionButtons
                  :types="types"
                  :isEdit="isEdit"
                  :EditSuccess="EditSuccess"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="iconcontainer" :class="{ hidden: !this.start }">
          <object
            type="image/svg+xml"
            class="svgicon"
            :data="dashboardicon"
            v-if="this.start"
          ></object>
          <div class="clickable" @click="letsstart()"></div>

          <div class="zuordnung-klick-info">Für Zuordnung <br/> Hier klicken!</div>
      </div>
      <div class="bannerright">
        <div :class="ZuordnungBannerClass">
          <div
            class="EBDnD"
            v-for="(q1, $index) in EBClone"
            :id="'Area' + $index"
            v-bind:key="'eb' + $index"
            :class="q1.colorClass"
          >
            <div
              class="stasheading"
              style="text-align: center; min-height: 5vh"
              :value="q1[$index]"
            >
              {{ q1.name }}
            </div>
            <vue-nestable
              :value="q1.children"
              v-model="q1.children"
              cross-list
              group="cross"
              class-prop="class"
              treshold="50"
              :maxDepth="1"
            >
              <div
                slot="placeholder"
                class="placeholderlist"
                style="text-align: center"
              >
                <b>Diese Liste ist noch leer</b>
                <p style="font-family: 'aileronregular'">
                  Ziehen Sie Entwicklungsziele von links hierhin.
                </p>
              </div>
              <vue-nestable-handle
                slot-scope="{ item }"
                class=" "
                :item="item"
              >
                <div class="sortlabel">{{ item.label }}</div>
                <div class="sortheader">
                  {{ item.name }}
                </div>
                <div
                  v-for="(q2, $index) in q1.children.filter(
                    (i) => i.id == item.id
                  )"
                  v-bind:key="'eb' + $index"
                >
                  <vue-nestable
                    :value="q2.childr"
                    v-model="q2.childr"
                    cross-list2
                    group="cross2"
                    class-prop="class"
                    treshold="50"
                    @change="saveZuordnung"
                  >
                    <div
                      slot="placeholder"
                      class="placeholderlist"
                      style="text-align: center; border-radius: 0px"
                    >
                      <b>Diese Liste ist noch leer</b>

                      <p style="font-family: 'aileronregular'">
                        Ziehen Sie Entwicklungsmaßnahmen von links hierhin.
                      </p>
                    </div>
                    <vue-nestable-handle
                      slot-scope="{ item }"
                      class=" "
                      :item="item"
                    >
                      <div class="sortlabel">{{ item.label }}</div>
                      <div class="sortheader">
                        {{ item.name }}
                      </div>
                    </vue-nestable-handle>
                  </vue-nestable>
                </div>
              </vue-nestable-handle>
            </vue-nestable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { VueNestable, VueNestableHandle } from "vue-nestable";
import GeneralCardActionButtons from "../Cards/GeneralCardActionButtons";

export default {
  props: ['zid', 'studentId'],
  name: "ZuordnungDnD",
  data() {
    return {
      goals: [],
      actions: [],
      k: [],
      meinsvg: require(`../../../assets/MMP.svg`),
      dashboardicon: require(`../../../assets/dashboard_black_24dp.svg`),
      start: true,
      showInfos: true,
      types: {
        isZZ: true,
      },
      EBClone: [],
      EBChange: false,
      EMClone: [],
      EMChange: false,
      EZClone: [],
      EZChange: false,
      ok: false,
      isEdit: true,
      EditSuccess: false,
    };
  },
  watch: {
      eb() {
        this.updateData()
      },
      elementEz() {
        this.updateData()
      },
      elementEm() {
        this.updateData()
      }
  },
  computed: {
    // drag and drop does not work with computed elements. workaround: create new objects which will changend by watchers
    eb() {
      return this.$store.getters.zyklusEbById({id: this.studentId, zid: this.zid})
    },
    elementEm: function () {
      let measures = this.$store.getters.entMkbyZid(this.zid)
      let measuresZuordnung = []
      if(measures && Array.isArray(measures) && measures.length) {
        for(let item of measures) {
          if(item) measuresZuordnung.push({
            name: item.Title,
            type: "em",
            id: item.Id,
            class: "sortcard actioncard",
            label: "Bezeichnung",
            RefZie: item.RefZie
          })
        }
      }
      return measuresZuordnung
    },
    elementEz: function () {
      let goals = this.$store.getters.entZiele(this.zid)
      let goalsZuordnung = []
      if(goals && Array.isArray(goals) && goals.length) {
        for(let item of goals) {
          if(item) goalsZuordnung.push({
            name: item.Title,
            type: "ez",
            id: item.Id,
            class: "sortcard",
            EBereiche: item.EBereiche,
            childr: []
          })
        }
      }
      return goalsZuordnung
    },
    ZuordnungBannerClass: function () {
      return {
        dndContainer: !this.start,
        dndContainerhidden: this.start,
      };
    },
    SortBannerClass: function () {
      return {
        bannerleftinfo: !this.showInfos,
      };
    },
    StashBannerClass: function () {
      return {
        stashbannerhidden: this.start,
        stashbannernormal: !this.start,
      };
    },
       ZitemsLength() {
      return this.EZClone.length;
    },
    MNitemsLength() {
      return this.EMClone.length;
    },
  },
  created() {
    this.updateData()
    // show loading screen
    this.start = true
  },
  methods: {
    updateData() {
      let usedEz = []
      let usedEm = []
      // deep copy: prevents errors generated by symbolic linking
      let elementEz = JSON.parse(JSON.stringify(this.elementEz))
      let elementEm = JSON.parse(JSON.stringify(this.elementEm))
      let elementEb = JSON.parse(JSON.stringify(this.$store.getters.ebZuordnung(this.eb)))
      // if there are no development areas we do not need to proceed
      if(elementEb && Array.isArray(elementEb) && elementEb.length) {
        // for each development area
        for(let eb of elementEb) {
          // for each goal, if the target has the same development area, mark it as used and add as children
          for(let ez of elementEz) {
            if(ez && ez.id && ez.EBereiche && eb && eb.name && eb.name === ez.EBereiche) {
              usedEz.push(ez.id)
              eb.children.push(ez)
              // zuordnung was already used: do not show loading screen when starting
              this.start = false
              // for each measure, if the measure has a goal which is linked to the actual goal, mark is  as used and add as children
              for(let em of elementEm) {
                if(em && em.RefZie && em.RefZie.Id && em.RefZie.Id === ez.id) {
                  usedEm.push(em.id)
                  ez.childr.push(em)
                }
              }
            }
          }
        }
      }
      //  add goal and measure cards which are not already used
      this.EZClone = this.elementEz.filter(item => !usedEz.includes(item.id))
      this.EMClone = this.elementEm.filter(item => !usedEm.includes(item.id))
      // set development areas which include already assigned goals and measures
      this.EBClone = elementEb
    },
    saveZuordnung() {
      // save tree of zuordnung
      if(this.EBClone && Array.isArray(this.EBClone) && this.EBClone.length) {
        // for each development area
        for(let eb of this.EBClone) {
          if(eb.children && Array.isArray(eb.children) && eb.children.length) {
            // for each goal of a development area
            for(let ez of eb.children) {
              // save goal
              this.$store.commit("setEntZielEBereiche", {
                goalId: ez.id,
                eBereiche: eb.name,
                zyklusId: this.zid
              })
              if(ez.childr && Array.isArray(ez.childr) && ez.childr.length) {
                // for each measure of a goal of a development area
                for(let em of ez.childr) {
                  // save measure
                  this.$store.commit("setEntMkiRefZie", {
                    measureId: em.id,
                    refZie: {Id: ez.id, Title: ez.name}
                  })
                }
              }
            }
          }
        }
      }
      // save unallocated goals
      for(let ez of this.EZClone) {
        this.$store.commit("setEntZielEBereiche", {
          goalId: ez.id,
          eBereiche: null,
          zyklusId: this.zid
        })
        if(ez.childr && Array.isArray(ez.childr) && ez.childr.length) {
          for(let em of ez.childr) {
            // save measure connected to an unallocated goal
            this.$store.commit("setEntMkiRefZie", {
              measureId: em.id,
              refZie: null
            })
          }
        }
      }
      // save unallocated measures
      for(let em of this.EMClone) {
        this.$store.commit("setEntMkiRefZie", {
          measureId: em.id,
          refZie: null
        })
      }
    },
    letsstart() {
      this.start = !this.start;
    },
    beforeMove({ dragItem, pathTo }) {
      if (dragItem.type === "em") {
        return pathTo.length === 0;
      }
      if (dragItem.type === "ez") {
        return pathTo.length === 1;
      }
    },
    showInfo: function () {
      this.showInfos = !this.showInfos;
    },
  },
  components: {
    VueNestable,
    VueNestableHandle,
    GeneralCardActionButtons
  },
};
</script>
  <style>
.arrangeBox {
  min-height: 80vh;
  width: 100%;
}
.placeholderlist {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  padding: 8px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  border-style: dotted;
  border-color: #686868;
}
.CardFormContainer {
  width: calc((100% - 1vw));
}

@media only screen and (min-width:600px), (max-width:1370px){
  .IntroHeading {
    font-size: calc( 18px + (32 - 18) * ((100vh - 300px) / (1900 - 300)) ) !important;
  }
  }
  @media only screen and  (min-width:1370px){
  .IntroHeading {
     font-size: calc(
    32px + (48 - 32) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  }
  }
@media only screen and (min-width: 1370px) {
  .bannerleft {
    width: 40%;
    height: 100%;
  }
}
.hidden {
  z-index: -1;
}
.svgimg {
  position: absolute;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.buttonarea {
  width: 80%;
  padding: 28px;
}
.bannerright {
  width: 60%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.iconcontainer {
  height: 100%;
  width: 100%;
  position: absolute;
}
.svgicon {
  width: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%) scale(1);
  padding: 50px;
  fill: rgba(0, 120, 212, 0.4);
  cursor: pointer;
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
}
.bannerContainerZ {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.bannerContainerZ--gradient {
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.7) 40%,
    rgba(255, 255, 255, 0) 40%
  );
}

.bannerleftsmall {
  width: 0px;
  -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  height: 100%;
  text-align-last: right;
}
.bannerleftsmall > .LeftiFirstIntroContainer {
  box-shadow: 4px 10px 14px 0px #000000;
  justify-content: center;
  /* TODO: Remove LeftiFirstIntroContainer if not needed  */
  display: none;
}
.closehelper {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 9;
  cursor: pointer;
}
.bannerleftsmall * .IntroHeading {
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  font-size: calc(
    16px + (22 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  cursor: pointer;
}
.bannerleftsmall * .IntroDescription {
  display: none;
}
.bannerleftinfo {
  position: absolute;
  width: 50%;
  z-index: 99;
  background: #ffffff70;
  height: 100%;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  cursor: pointer;
}
@supports not ((-webkit-backdrop-filter: 15px) or (backdrop-filter: 15px) ){
  .bannerleftinfo  {
    background: rgba(255, 255, 255, .95);
  }
}
.stashbannernormal {
  position: absolute;
  top: 0;
  width: calc(40% - 68px);

  margin-left: 68px;
  height: 100%;
}
.goalstash,
.actionstash {
  display: flex;
  flex-wrap: wrap;
}
.stashbannerhidden {
  display: none;
}
.stasheading {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.IntroHeading {
  /* font-size: calc(
    36px + (52 - 36) * ((100vw - 300px) / (1900 - 300))
  ) !important; */

  line-height: calc(1.3em + (1.5 - 1.2) * ((100vh - 300px) / (1600 - 300)));
  /* TODO: Check if this will have no side effect */
  /* width: 100%; */
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  font-weight: 700;
  color: rgb(36 50 64);
  font-family: "aileronheavy", sans-serif;
}
.LeftiSecondIntroContainer,
.LeftiFirstIntroContainer {
  overflow-y: auto;
      overflow-x: hidden;
  height: calc(100% - 56px);
  padding: 28px;
  display: grid;


}
.LeftiSecondIntroContainer {
  padding-left: 8px;
    box-sizing: border-box;
}
.sortcard {
  background-color: white;
  margin: 5px;
  padding: 20px;
  border-radius: 20px;
  line-height: 1em !important;
  -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  display: grid;
  grid-template-rows: auto;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.actioncard {
  border-left: 20px solid #ffe37e;
  padding-left: 0px;
  border-radius: 0px;
}
.sortlabel {
  margin-bottom: 5px;
  font-size: calc(
    16px + (18 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  font-family: "aileronthin", sans-serif !important;
  line-height: 1em !important;
}
.stashcontainer {
  font-family: "aileronheavy", sans-serif;
  font-size: 90%;
  width: 92%;
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.sortheader {
  word-wrap: anywhere;
  margin-top: -2px;
  margin-right: 30px;
  margin-bottom: 10px;
  font-size: calc(
    18px + (19 - 16) * ((100vh - 300px) / (1900 - 300))
  ) !important;
  font-family: "aileronregular" !important;
  line-height: 1em !important;
  font-weight: bold;
}
.dndContainer {
  display: grid;
  /* grid-template-rows: repeat(auto-fit, minmax(30%, 1fr)); */
  grid-template-columns: 1fr;
  gap: 1.5em 1.5em;
  grid-template-areas:
    "Area0"
    "Area1"
    "Area2";
  height: calc(100% - 56px);
  padding: 28px;
  overflow-y: auto;
}
.dndContainerhidden {
  display: none;
}
.clickable {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
  top: 0;
  z-index: 1000;
}
.Area0 {
  grid-area: Area0;
}
.Area1 {
  grid-area: Area1;
}
.Area2 {
  grid-area: Area2;
}
.EBDnD {
  padding: 28px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  border-radius: 20px;
  font-family: "aileronheavy", sans-serif;
  font-size: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  align-content: flex-start;
  border: solid;
  /* pointer-events: none; */
}
.slide-up-fade-in {
  animation: slide-up-fade-in ease 1.2s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1.2s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up-fade-in ease 1.2s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1.2s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1.2s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/

  opacity: 0;
  opacity: 1\9;
}

@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(0px, 40px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}
.bannerleft {
  width: 40%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}
@media only screen and (min-width: 1370px) {
  .dndContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    grid-template-rows: 1fr;
    gap: 1.5em 1.5em;
    grid-template-areas: "Area0 Area1 Area2";
    padding: 28px;
    width: calc(100% - 56px);
    height: calc(100% - 56px);
  }
}
.nestable {
  position: relative;
  width: 100%;
}
.nestable-rtl {
  direction: rtl;
}
.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}
.nestable-rtl .nestable-list {
  padding: 0 40px 0 0;
}
.nestable > .nestable-list {
  padding: 0;
}
.nestable-item,
.nestable-item-copy {
  margin: 10px 0 0;
}
.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  margin-top: 10px;
}
.nestable-item {
  position: relative;
}
.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}
.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}
.nestable-item.is-dragging:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 127, 233, 0.274);
  border: 1px dashed rgb(73, 100, 241);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}
.nestable-rtl .nestable-drag-layer {
  left: auto;
  right: 0;
}
.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(106, 127, 233, 0.274);
}
.nestable-rtl .nestable-drag-layer > .nestable-list {
  padding: 0;
}
.nestable [draggable="true"] {
  cursor: move;
  margin: -20px;
  padding: 20px;
}
.nestable-item-content {
  width: 100%;
  word-break: break-word;
}
/* .nestable-handle {
  display: inline;
} */

.intro-heading-container {
  display: flex;
}

.zuordnung-klick-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    text-align: center;
}

.container {
  width: 100%;
}

</style>
