<template>
    <div class="delete-button-container">
        <div
      class="delete-button"
      :class="[
        confirmationVisible ? 'confirmation' : '',
        size === 'small' ? 'small-button' : '',
        isActive ? 'delete-button-active' : '',
        isDisabled ? 'delete-button-disabled' : ''
      ]"
      @click="handleClick"
    >
       
        <div v-if="!confirmationVisible" class="delete-button-icon">
          <i class="ms-Icon ms-Icon--Delete"></i>
        </div>
        <div v-if="confirmationVisible" class="confirmation-container">
            <div class="confirmation-text-container">
          <div class="confirmation-text">{{ confirmationText }}</div>
        </div>
        <div class="cancel-icon-container">
          <div class="cancel-icon" @click.stop="cancelDeletion">
            <i class="ms-Icon ms-Icon--Cancel"></i> 
        </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      size: {
        type: String,
        default: 'large',
        validator: value => ['small', 'large'].includes(value),
      },
      confirmationText: {
        type: String,
        default: 'endgültig löschen',
      },
      isActive: {
      type: Boolean,
      default: false,
    },
    isDisabled: { 
      type: Boolean,
      default: false,
    },
    },
    data() {
      return {
        confirmationVisible: false,
      };
    },
    methods: {
        handleClick() {
      if (this.isDisabled) return; 
      if (this.confirmationVisible) {
        this.$emit('delete');
      } else {
        this.confirmationVisible = true;
      }
    },
      cancelDeletion() {
        this.confirmationVisible = false;
      },
    },
  };
  </script>
  
  <style>
  .delete-button {
    display: inline-flex; 
  align-items: center; 
  cursor: pointer;
  padding: 0px 11px;
  max-height: 50px;
  min-width: auto;
  font-size: 16px;
  overflow: hidden;
  position: relative;
  border: 1px solid black;
  transition: all 0.3s ease, box-shadow .15s ease;
  background-color: #e74c3c82;
  backdrop-filter: blur(10px);
  box-shadow: var(--button-elevation-medium);
  }
  .delete-button-icon {
    padding: 8px 8px 8px 8px;
    font-size: 20px;
 
  }
  .confirmation-container {
    display: inline-flex;
    border: 0px solid black;
    /* Stile für den Bestätigungstext und das Abbrechen-Symbol */
  }

  .cancel-icon-container,
  .confirmation-text-container {

padding: 8px 8px 8px 8px;
border: 1px solid black;
  }
  .cancel-icon-container:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  .confirmation-text-container:hover {
    background: #e74c3cb5;
  }
  .cancel-icon-container {
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.67);
    padding-left: 14px;
padding-right: 14px;
 border: 1px solid black;
  }
  .small-button {
    max-height: 30px;
  padding: 0px 13px;

  }
  .confirmation {
  padding: 0;

  }
  

.delete-button:hover:not(.confirmation) {
  background: #e74c3cb5;
  filter: brightness(1.3);
}
.delete-button:hover > .delete-button-icon > i {
  font-size: 21px;
}

.delete-button-disabled {
  background: #ccc;
  color: #5b5b5b;
  pointer-events: none;
  border: 1px solid #aaa;
  box-shadow: var(--button-elevation-low);
}

  </style>
  