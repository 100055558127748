/*global _spPageContextInfo*/
/*eslint no-undef: "error"*/

import Vue from "vue";
import App from './App.vue';
import Vuex from "vuex";
import VueRouter from 'vue-router';
import VueFluent from "vfluentdesign";
import 'vfluentdesign/lib/index.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import 'material-design-icons/iconfont/material-icons.css';
import 'es6-promise/auto';
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import routes from './routes.js';
import store from './store';
import xmodal from "xmodal-vue";
import vClickOutside from 'v-click-outside';
import VueNumber from "vue-number-animation";
import wysiwyg from "vue-wysiwyg";
import contenteditable from 'vue-contenteditable';
import HelperIconCallout from './components/layout/Reusable/HelperIconCallout.vue';
import Aussehen from './components/aussehen-vue.vue';
import { entgendern } from "./variables.js";
import {
  SharedElementRouteGuard,
  SharedElementDirective
} from 'v-shared-element';
import dayjs from 'dayjs';
import 'dayjs/locale/de'; // importieren Sie die deutsche Lokalisierung
dayjs.locale('de'); // verwenden Sie die deutsche Lokalisierung

import { dateformer } from './filters';

//Vue Filter
Vue.filter('dateformer', dateformer);


// Vue Plugins
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueFluent, Vuex);
Vue.use(xmodal);
Vue.use(SharedElementDirective);
Vue.use(vClickOutside);
Vue.use(VueNumber);
Vue.use(wysiwyg);
Vue.use(contenteditable);
Vue.use(Aussehen);


// Vue Configuration
Vue.config.devtools = true;
Vue.config.productionTip = false;

// Globally Registered Components
Vue.component("HelperIconCallout", HelperIconCallout);

// Prototype Properties
Vue.prototype.$entgendern = entgendern; // Make $entgendern accessible to all Vue components
Vue.prototype.$dayjs = dayjs;

// Router Configuration
const router = new VueRouter({ routes });
router.beforeEach((to, from, next) => {
  // Check all parameters of the target route
  for (const value of Object.values(to.params)) {
    // Check if the value is null or undefined
    if (value == null) {
      next('/error'); // Redirect to error page
      return;
    }
    // Additional checks can be added here, e.g., type checks
  }
  
  // If all checks pass, proceed
  SharedElementRouteGuard(to, from, next);
});

// Vue Instance
new Vue({
  el: '#app',
  store,
  router,
  created() {
    // Set API end point based on the environment
 //   this.$store.commit("setApi", typeof _spPageContextInfo !== "undefined" ? "sharepoint" : "pepApi");
  //  console.log("API set to:", this.$store.getters.api); // Überprüfen des gesetzten API-Wertes
  //let apiValue = typeof _spPageContextInfo !== "undefined" ? "sharepoint" : "pepApi";
  let apiValue = typeof _spPageContextInfo !== "undefined" ? "localStorage" : "localStorage"
  console.log("Setting API to:", apiValue);
  this.$store.commit("setApi", apiValue);
  
    // Load initial data and settings
    this.$store.commit('setColorClass', localStorage.getItem('colorClass'));
    this.$store.commit('setZid', parseInt(localStorage.getItem('zid')));
  },
  render: h => h(App),
}).$mount('#app');
// Now the app has started!
