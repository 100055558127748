// ActionsCardSet.vue
<template>
    <div class="cardset" >
      
   <!-- <transition-group  name="kommup-in"  >  -->
    <ActionsCard
        v-for="(card, $index) in cards"
        :key="$index"
        :card="card"
        :position="(-200 * $index)"
        @setaction="setactionDetails($event, card)"
        :isDisabled="deleteStateId !== null && deleteStateId !== card.Id" 
        @showConfirmation="setDeleteState"
        @cancelConfirmation="cancelDeleteState"
        :class="deleteStateId === card.Id ? 'high-elevation' : ''"
        >
        
    </ActionsCard>
          
   <!-- </transition-group> -->
</div>
</template>
<script>
import ActionsCard from './ActionsCard.vue';
export default {
name: 'ActionsCardSet',
    props: {
        cards: {
            type: Array,
            default() {
        return []
      },
        },
        
    },
    data() {
        return {
            topo: process.env.BASE_URL + 'img/topo.svg', 
            deleteStateId: null, 

        }
    },
    methods: {
        setactionDetails($event, payload){

           const clickedCard = event.target.parentNode.parentNode.parentElement;
  this.$emit('setactionDetails', {clickedCard, payload});
    },
    setDeleteState(id) {
      this.deleteStateId = id;
    },
    cancelDeleteState() {
      this.deleteStateId = null;
    },
    },
    mounted() {
    let img = new Image();
    img.onload = () => {
        this.backgroundImage = `url(${img.src})`;
    };
    img.src = this.topo;
},
    components: {
        ActionsCard
    }
}
</script>
<style scoped>
.cardset {
    display: inline-grid;
  margin-left: 0;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(450px, 2fr)) ;
  grid-gap: 2%;
  place-content: space-between;
  margin-top: 4%;

}
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .cardset {
        grid-template-columns: repeat(auto-fit, minmax(450px, 2fr)) ;    }
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .cardset {
        grid-template-columns: repeat(auto-fit, minmax(300px, 2fr)) ;
    }

}
</style>