export default {
  loadEntPl() {
    let entPl = JSON.parse(localStorage.getItem('entPl'))
    return promise(entPl)
  },
  loadSVz() {
    let svz = JSON.parse(localStorage.getItem('svz'))
    return promise(svz)
  },
  updateSVz(payload) {
    let svz = getItem('svz')
     if(svz && payload) {
       svz.forEach(svz => {
         if(svz.Id === payload.svzId) {
           console.log(svz)
           // update svz
           svz.Title = payload.Title
           svz.FirstName = payload.FirstName
           svz.ImportId = payload.ImportId
           svz.Klasse = payload.Klasse
         }
       })
    console.log(payload)
      setItem('svz', svz)
      return promise(true)
    }
  },
  getDiagnostikObject() {
    let diagnostik = JSON.parse(localStorage.getItem('diagnostik'))
    return promise(diagnostik)
  },
  addEntPl(student) {
    return this.loadEntPl()
    .then(entPl => {
      entPl.push(student)
      localStorage.setItem('entPl', JSON.stringify(entPl))
      return promise(student)
    })
  },
  updateSettings(data) {
    let administrationObject = getItem('administrationObject')
    let GroupAdministration = getItem('GroupAdministration')
     if(administrationObject && data) {
      
      administrationObject.groupsSet.forEach(gs => {
        if(gs.Id === data.gdId) {
           
           // update svz
           if (gs.Settings === null)
           {
             gs.Settings = {"__metadata":{"type":"Collection(Edm.String)"},"results":data.Settings.results}
           }
           else {
           // update svz
           gs.Settings.results =  data.Settings.results
           }
         }
       })
       administrationObject.groupsSettings = data.Settings.results
       GroupAdministration.forEach(gs => {
        if(gs.Id === data.gdId) {
          
           if (gs.Settings === null)
           {
             gs.Settings = {"__metadata":{"type":"Collection(Edm.String)"},"results":data.Settings.results}
           }
           else {
           // update svz
           gs.Settings.results =  data.Settings.results
           }
         }
       })
       administrationObject.updateSettings = true
      setItem('administrationObject', administrationObject)
      setItem('GroupAdministration', GroupAdministration)
      return promise(true)
    }
  },
  getGroupObject() {
    let groupObject = JSON.parse(localStorage.getItem('groupObject'))
    return promise(groupObject)
  },
  getGroupAdministration() {

  let GroupAdministration = JSON.parse(localStorage.getItem('GroupAdministration'))
  return promise(GroupAdministration)
  
  },
  getUserObject() {
    let userObject = JSON.parse(localStorage.getItem('userObject'))
    return promise(userObject)
  },
  getAdministrationObject() {
    let administrationObject = JSON.parse(localStorage.getItem('administrationObject'))
return promise(administrationObject)
  },
  loadZyklen(id) {
    let zyklen = getItem('zyklen')
    if(zyklen) zyklen = zyklen.filter(item => id === item.RefPEPId).reverse()
    else zyklen = []
    return promise(zyklen)
  },
  addZyklus(zyklus) {
    let zyklen = getItem('zyklen')
    if(zyklen) zyklen.push(zyklus)
    else zyklen = [zyklus]

    setItem('zyklen', zyklen)
    return promise(zyklus)
  },
  agreeDatenschutz(id) {
    let entPl = getItem('entPl')
    if(id && entPl) {
      entPl.find(item => item.Id === id).DSchutzE = true
      setItem('entPl', entPl)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entPl available')))
  },
  changePiC() {
    return promise(true)
  },
  changeZyknPiC() {
    return promise(true)
  },
  deleteUser(id) {
    let entPl = getItem('entPl')
    if(id && entPl) {
      entPl = entPl.filter(item => item.Id !== id)
      setItem('entPl', entPl)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entPl available')))
  },
  deleteUserSVz(id) {
    let svz = getItem('SVz')
    if(id && svz) {
      svz = svz.filter(item => item.Id !== id)
      setItem('entPl', svz)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no svz available')))
  },
  deleteCycl(id) {
    let cycl = getItem('zyklen')
    if(id && cycl) {
      cycl = cycl.filter(item => item.Id !== id)
      setItem('zyklen', cycl)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },

  saveZyklusEb(payload) {
    let zyklen = getItem('zyklen')
    if(zyklen && payload.zid && payload.eb) {
      zyklen.find(item => item.Id === payload.zid).EB = JSON.stringify(payload.eb)
      setItem('zyklen', zyklen)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },
  saveZyklusAbschluss(payload) {
    let zyklen = getItem('zyklen')
    if(zyklen && payload.zid && payload.abschluss) {
      zyklen.find(item => item.Id === payload.zid).Abschluss = payload.abschluss
      setItem('zyklen', zyklen)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },
  saveZyklusEval(payload) {
    let zyklen = getItem('zyklen')
    if(zyklen && payload.zid) {
      zyklen.find(item => item.Id === payload.zid).Eval = payload.eval
      setItem('zyklen', zyklen)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no zyklen available')))
  },
  loadEntZiele(zid) {
    let entZiele = getItem('entZiele')
    if(entZiele) entZiele = entZiele.filter(item => zid === item.RefZ)
    else entZiele = []
    return promise(entZiele)
  },
  addEntZiel(goal) {
    let goals = getItem('entZiele')
    if(goals) goals.push(goal)
    else goals = [goal]

    setItem('entZiele', goals)
    return promise(goal)
  },
  removeEntZiel(payload) {
    let goals = getItem('entZiele')
    if(goals && payload.goalId) {
      goals = goals.filter(item => item.Id !== payload.goalId)
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no goals available')))
  },
  updateEntZiel(payload) {
    let goals = getItem('entZiele')
    if(goals && payload.goalId && payload.zyklusId) {
      goals.forEach(goal => {
        if(goal.Id === payload.goalId && goal.RefZ === payload.zyklusId) {
          // update goal
          goal.Title = payload.Title
          goal.IstStand = payload.IstStand
          goal.Entwicklungsziel = payload.Entwicklungsziel
        }
      })
      setItem('entZiele', goals)
      return promise(true)
    }
  },
  saveEntZielReuse(payload) {
    let goals = getItem('entZiele')
    if(goals && payload.goalId) {
      goals.find(item => item.Id === payload.goalId && item.RefZ === payload.zyklusId).Reuse = payload.reuse
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no goals available')))
  },
  saveZielEvaluation(evalItem) {
    let goals = getItem('entZiele')
    if(goals && evalItem && evalItem.Id) {
      goals.find(item => item.Id === evalItem.Id && item.RefZ === evalItem.RefZ).EvalObj = JSON.stringify(evalItem)
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entZiele available')))
  },
  setEntZielEBereiche(payload) {
    let goals = getItem('entZiele')
    if(goals && payload && payload.goalId) {
      goals.find(item => item.Id === payload.goalId && item.RefZ === payload.zyklusId).EBereiche = payload.eBereiche
      setItem('entZiele', goals)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entZiele available')))
  },
  loadEntMk() {
    let entMk = getItem('entMk')
    return promise(entMk)
  },
  addEntMki(entMki) {
    let entMk = getItem('entMk')
    if(entMk) entMk.push(entMki)
    else entMk = [entMki]

    setItem('entMk', entMk)
    return promise(entMki)
  },
  addEntMTki(entMTkii) {
    let entMTki = getItem('entMTki')
    if(entMTki) entMTki.push(entMTkii)
    else entMTki = [entMTkii]

    setItem('entMk', entMTki)
    return promise(entMTki)
  },
  addEntMTkg(entMTkgg) {
    let entMTkg = getItem('entMk')
    if(entMTkg) entMTkg.push(entMTkgg)
    else entMTkg = [entMTkgg]

    setItem('entMk', entMTkg)
    return promise(entMTkgg)
  },
  removeEntMki(payload) {
    let entMk = getItem('entMk')
    if(entMk && payload.measureId) {
      entMk = entMk.filter(item => item.Id !== payload.measureId)
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no measures available')))
  },
  updateEntMki(payload) {
    let entMk = getItem('entMk')
    if(entMk && payload.measureId && payload.zyklusId) {
      entMk.forEach(entMki => {
        if(entMki.Id === payload.measureId && entMki.RefZ === payload.zyklusId) {
          // update measure
          entMki.Title = payload.Title
          entMki.Kurzbeschreibung = payload.Kurzbeschreibung
          entMki.Sozialform = payload.Sozialform
          entMki.Voraussetzungen_x002f_Materialie = payload.Voraussetzung
          entMki.Zeitumfang = payload.Zeitumfang
        }
      })
      setItem('entMk', entMk)
      return promise(true)
    }
  },
  updateEntMTki(payload) {
    let entMTki = getItem('entMTki')
    if(entMTki && payload.Id ) {
      entMTki.forEach(entMTkii => {
        entMTkii.Title = payload.Title,
        entMTkii.RefMethZiel = payload.RefMethZiel,
        entMTkii.RefMethBeschr = payload.RefMethBeschr,
        entMTkii.Zeitumfang = payload.Zeitumfang,
        entMTkii.Sozialform = payload.Sozialform,
        entMTkii.Voraussetzungen_x002f_Materialie = payload.Voraussetzungen_x002f_Materialie,
        entMTkii.Kurzbeschreibung = payload.Kurzbeschreibung,
        entMTkii.Evaluationskriterien_x0020__x002 = payload.Eval,
        entMTkii.RefMethId = payload.RefMethId,
        entMTkii.Lernumgebung = payload.Lernumgebung,
        entMTkii.Altersgruppe = payload.Altersgruppe,
        entMTkii.Praxistipps = payload.Praxistipps,
        entMTkii.EntwicklBereiche = payload.EntwicklBereiche,
        entMTkii.Plan_und_Durch = payload.Plan_und_Durch,
        entMTkii.Beispiel = payload.Beispiel,
        entMTkii.RefTemplategroupId = payload.RefTemplategroup
    })
  }
    
  setItem('entMTki', entMTki)
  return promise(true)
  },

  updateEntMTkg(payload) {
    let entMTkg = getItem('entMTkg')
    if(entMTkg && payload.Id ) {
      entMTkg.forEach(entMTkgg => {
        entMTkgg.Title = payload.Title,
        entMTkgg.RefMethZiel = payload.RefMethZiel,
        entMTkgg.RefMethBeschr = payload.RefMethBeschr,
        entMTkgg.Zeitumfang = payload.Zeitumfang,
        entMTkgg.Sozialform = payload.Sozialform,
        entMTkgg.Voraussetzungen_x002f_Materialie = payload.Voraussetzungen_x002f_Materialie,
        entMTkgg.Kurzbeschreibung = payload.Kurzbeschreibung,
        entMTkgg.Evaluationskriterien_x0020__x002 = payload.Eval,
        entMTkgg.RefMethId = payload.RefMethId,
        entMTkgg.Lernumgebung = payload.Lernumgebung,
        entMTkgg.Altersgruppe = payload.Altersgruppe,
        entMTkgg.Praxistipps = payload.Praxistipps,
        entMTkgg.EntwicklBereiche = payload.EntwicklBereiche,
        entMTkgg.Plan_und_Durch = payload.Plan_und_Durch,
        entMTkgg.Beispiel = payload.Beispiel,
        entMTkgg.AdminChecked = payload.AdminChecked
    })
  }
    
  setItem('entMTkg', entMTkg)
  return promise(true)
  },
   
  saveEntMkiReuse(payload) {
    let entMk = getItem('entMk')
    if(entMk && payload.measureId) {
      entMk.find(item => item.Id === payload.measureId).Reuse = payload.reuse
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entMk available')))
  },
  saveMkiEvaluation(evalItem) {
    let entMk = getItem('entMk')
    if(entMk && evalItem && evalItem.Id) {
      entMk.find(item => item.Id === evalItem.Id).EvalObj = JSON.stringify(evalItem)
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entMk available')))
  },
  setEntMkiRefZie(payload) {
    let entMk = getItem('entMk')
    if(entMk && payload.measureId) {
      entMk.find(item => item.Id === payload.measureId).RefZie = payload.refZie
      setItem('entMk', entMk)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no entMk available')))
  },
  loadAurList(studentId) {
    let aurList = getItem('aurList')
    if(aurList) aurList = aurList.filter(item => studentId === item.RefPEPId)
    else aurList = null
    return promise(aurList)
  },
  addAur(aur) {
    let aurList = getItem('aurList')
    if(aur && aurList) aurList.push(aur)
    else aurList = [aur]
    setItem('aurList', aurList)
    return promise(aur)
  },
  saveAur(aur) {
    let aurList = getItem('aurList')
    if(aurList && aur && aur.Id) {
      // the aur is uniquely identified by the combination of id, studentId and zyklusId
      aurList.find(item => item.Id === aur.Id && item.RefPEPId === aur.RefPEPId && item.RefZId === aur.RefZId).AuRF = aur.AuRF
      setItem('aurList', aurList)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no aurList available')))
  },
  loadNotes(studentId) {
    let notes = getItem('notes')
    if(notes) notes = notes.filter(item => studentId === item.RefPEPId)
    else notes = null
    return promise(notes)
  },
  addNote(note) {
    let notes = getItem('notes')
    if(note && notes) notes.push(note)
    else notes = [note]
    setItem('notes', notes)
    return promise(note)
  },
  removeNote(payload) {
    let notes = getItem('notes')
    if(notes && payload.noteId && payload.RefPEPId) {
      notes = notes.filter(item => {
        // the note is uniquely identified by the combination of id and studentid
        if(item.Id === payload.noteId && item.RefPEPId === payload.RefPEPId) return false
        else return true
      })
      setItem('notes', notes)
      return promise(true)
    }
    else return new Promise((resolve, reject) => reject(new Error('no notes available')))
  },
  updateNote(payload) {
    let notes = getItem('notes')
    if(notes && payload.noteId && payload.RefPEPId) {
      notes.forEach(note => {
        if(note.Id === payload.noteId && note.RefPEPId === payload.RefPEPId) {
          // update note
          note.Title = payload.Title
          note.OData__Comments = payload.OData__Comments
          note.RefZId = payload.RefZId
        }
      })
      setItem('notes', notes)
      return promise(true)
    }
  },
  setLocalData(data) {
    // replace localStorage with local data
    if(data && Array.isArray(data.entPl) && data.entPl.length) localStorage.setItem('entPl', JSON.stringify(data.entPl))
    if(data && Array.isArray(data.svz) && data.svz.length) localStorage.setItem('svz', JSON.stringify(data.svz))
    if(data && Array.isArray(data.zyklen) && data.zyklen.length) localStorage.setItem('zyklen', JSON.stringify(data.zyklen))
    if(data && Array.isArray(data.entZiele) && data.entZiele.length) localStorage.setItem('entZiele', JSON.stringify(data.entZiele))
    if(data && Array.isArray(data.entMk) && data.entMk.length) localStorage.setItem('entMk', JSON.stringify(data.entMk))
    if(data && Array.isArray(data.entMTki) && data.entMTki.length) localStorage.setItem('entMTki', JSON.stringify(data.entMTki))
    if(data && Array.isArray(data.entMTkg) && data.entMTkg.length) localStorage.setItem('entMTkg', JSON.stringify(data.entMTkg))
    if(data && Array.isArray(data.aurList) && data.aurList.length) localStorage.setItem('aurList', JSON.stringify(data.aurList))
    if(data && Array.isArray(data.notes) && data.notes.length) localStorage.setItem('notes', JSON.stringify(data.notes))
    if(data && data.userObject) localStorage.setItem('userObject', JSON.stringify(data.userObject))
    if(data && data.groupObject) localStorage.setItem('groupObject', JSON.stringify(data.groupObject))
    if(data && Array.isArray(data.GroupAdministration) && data.GroupAdministration.length) localStorage.setItem('GroupAdministration', JSON.stringify(data.GroupAdministration))
    if(data && data.administrationObject) localStorage.setItem('administrationObject', JSON.stringify(data.administrationObject))
    if(data && data.diagnostik) localStorage.setItem('diagnostik', JSON.stringify(data.diagnostik))
    if(data && Array.isArray(data.documents) && data.documents.length) localStorage.setItem('documents', JSON.stringify(data.documents))

    return promise(true)
  },
  createLog() {
    return promise(true)
  },
  loadLogData() {
    return promise(null)
  },
  loadDocuments(payload) {
    let documents = JSON.parse(localStorage.getItem('documents'))
    if(documents && Array.isArray(documents) && documents.length) documents = documents.filter(item => item.chiffre === payload.chiffre)
    return promise(documents)
  },
}

function promise(data) {
  return new Promise( resolve => {
    setTimeout(() => {
      if(data === "undefined" || data === null) resolve([])
      else resolve(data)
    }, 200)
  })
}

function getItem(itemName) {
  return JSON.parse(localStorage.getItem(itemName))
}
function setItem(itemName, item) {
  localStorage.setItem(itemName, JSON.stringify(item))
}
