import store from "../index"
import api from '../../api/api.js'
import { changeEntPl, waitForColleagues } from "../../utils/dataUtils"


const state = {
  entPl: null,
  processedEntPl: null,
  loading: null,
  isFetching: false, 
  order: "alphaPupilAsc",
  view: "card",
  query: "",
  colorClass: ""
}

const actions = {
  loadEntPl({commit, dispatch, state}) {

    // only load, if necessary
    if(state.entPl === null) {
      commit('setEntPlLoading', true);
      commit('setFetching', true); 
      api.loadEntPl()
      .then(res => {
        if (res !== undefined) {
          // if localStorage is empty: fill it with data
          if(res.length === 0 && store.getters.api === "localStorage") {
            store.dispatch("importLocalData")
          }
          else{ commit('setEntPl', res)
          dispatch('processAndLoadEntPl', res);
        }
        } else {
          console.log("Received undefined response");
        }
      
      })
      .catch(err => {
        console.log("Error while getting entPl: " +err)
      })
      .finally(() => {
        setTimeout(() => {
          commit('setEntPlLoading', false);
          commit('setFetching', false);
        }, 1000)
      })
    }
    
  },
  async processAndLoadEntPl({ commit }, entPl) {
    const processedData = await changeEntPl(entPl);
    commit('setProcessedEntPl', processedData);
  },
  async updateEntPl({ commit }, entPl) {
    if (entPl === null) {
      console.warn("entPl is null");
      return;
    }
    console.log("Original entPl:", entPl);
    const changedEntPl = await changeEntPl([...entPl]);
    if (changedEntPl === null) {
      console.warn("changedEntPl is null");
      return;
    }
    console.log("Changed entPl:", changedEntPl);
    if (JSON.stringify(changedEntPl) !== JSON.stringify(entPl)) {
      commit('setEntPl', changedEntPl);
    }
  },    
  createNewEntPl({commit}, student) {
    api.addEntPl(student)
    .then(response => {
      console.log(response);
      let newStudent = response; // Direkte Zuweisung des Antwortobjekts
      if (!newStudent) {
        console.warn("Keine gültigen Ergebnisse erhalten");
      } 
      commit('addEntPl', newStudent);
      store.commit('addSVz', newStudent);
    })
    .catch(err => {
      // Testgrund
      alert("Es trat ein Problem mit der Serververbindung auf. Es konnte leider kein neuer Entwicklungsplan gespeichert werden.");
      console.error("Error while saving entpl:", err);
    });
  },
  
  agreeDatenschutz({commit}, id) {
    commit('setEntPlLoading', true)
    api.agreeDatenschutz(id)
    .then(res => {
      commit('setDatenschutz', {id: id, value: res})
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Die Zustimmung zum Datenschutz konnte leider nicht gespeichert werden.")
      console.log("Error while saving datenschutz: " +err)
    })
    .then(() => {
      commit('setEntPlLoading', false)
    })
  },
  changePiC({commit}, payload) {
    commit('setEntPlLoading', true)
    api.changePiC(payload)
    .then(res => {
      commit('setPiC', payload, res)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Die Veränderung der Verantwortlichkeiten konnte nicht gespeichert werden")
      console.log("Error while saving datenschutz: " +err)
    })
    .then(() => {
      commit('setEntPlLoading', false)
    })
  },
  deleteUser({commit}, id) {
    commit('setEntPlLoading', true)
    api.deleteUser(id)
    .then(() => {
      commit('deleteUser', id)
    })
    .catch(err => {
      alert("Es trat ein Problem mit der Serververbindung auf. Das Löschen der Schüler:in konnte leider nicht verarbeitet werden.")
      console.log("Error while deleting student: " +err)
    })
    .then(() => {
      commit('setEntPlLoading', false)
    })
  },

  async updateEntplVerantwortliche({commit}, payload) {
    console.log("updateEntplVerantwortliche: ", payload)
    commit("setEntplPiC", payload);
    try {
      await api.updateEntplVerantwortliche(payload);
       console.log("updateEntplVerantwortliche called with payload:", payload);
    } catch (err) {
      console.log("Error while adding plan verantwortliche: " + err);
    }
  }
}
const getters = {
  entPl: state => {
    
    // fetch data if no other data is available or there is no other fetching process.
    checkAndLoadEntPl(state);
    return state.entPl
  },
  entPl2: state => {
    
    // fetch data if no other data is available or there is no other fetching process.
    checkAndLoadEntPl(state);
    return state.processedEntPl
  },
  
  nonadminentPl: state => {
    
    let fe = state.entPl
    let fefe
    checkAndLoadEntPl(state);
    if (fe != null && fe.length && Array.isArray(fe) && store.getters.adminsettings != null && Array.isArray(store.getters.adminsettings) && store.getters.adminsettings.length) {
    //  console.log(fe)
  //    console.log(fe.filter(p => p.isAdminImport === false))
      fefe = fe.filter(p => p.isAdminImport === false)
    //  console.log(fefe)

    }


    return fefe
  },
  entPlLoading: state => {
    return state.loading
  },
  entPlOrder: state => {
    return state.order
  },
  entPlView: state => {
    return state.view
  },
  entPlQuery: state => {
    return state.query
  },
  entPlbyId: state => id => {
    
    // fetch data if needed
    checkAndLoadEntPl(state);
    // get entpl by id
    if(state.entPl !== null && id) return state.entPl.find(item => item.Id === id)
    return null
  },
  entPlbySVzId: state => id => {
    
    // fetch data if needed
    checkAndLoadEntPl(state);
    // get entpl by id
    if(state.entPl !== null && id) return state.entPl.find(item => item.RefS.Id === id)
    return null
  },
  studentNameById: () => id => {
    let entpl = store.getters.entPlbyId(id)
    if(entpl && entpl.RefS) return entpl.RefS.FirstName +" " +entpl.RefS.Title
    return null
  },
  entPlbyRefSId: state => id => {
    
    // fetch data if needed
    checkAndLoadEntPl(state);
    if(state.entPl !== null && id) return state.entPl.find(item => item.RefSId === id)
    return null
 },

 entplPiC:() => async id => {
  console.log("entplPiC called with id:", id); // Log the id when the function is called
  let entpl = store.getters.entPlbyId(id);
  console.log("Entpl data:", entpl); // Log the entpl data
  if (entpl === null) {
    console.warn("entpl ist null für ID:", id);
    return [];  // oder eine andere sinnvolle Aktion
  }
  let colleagues = await waitForColleagues(); // Warte auf die Kolleg:innendaten
  console.log("Colleagues data:", colleagues); // Log the colleagues data
  
  // Kein Bedarf für 'if (!colleagues)' hier, da waitForColleagues sicherstellt, dass colleagues vorhanden ist.
  console.log("Entpl.PiC:", JSON.stringify(entpl.PiC, null, 2));
  console.log("Entpl.PiCId:", JSON.stringify(entpl.PiCId, null, 2));
  
  if (entpl) {
    let ids = [];
    console.log("Entplpiciid data:", entpl.PiCId && Array.isArray(entpl.PiCId.results) && entpl.PiCId.results.length > 0)
    console.log("Entplpiciid datapoint:", entpl.PiCId)
    console.log("Entplpiciid datapoint:", entpl.PiC)
    console.log("Entplpic data:", entpl.PiC && Array.isArray(entpl.PiC.results) && entpl.PiC.results.length > 0)
    // Check if PiCId is available and has results
   // Überprüfen Sie, ob PiCId verfügbar ist und Ergebnisse hat
   if (entpl.PiCId && Array.isArray(entpl.PiCId.results) && entpl.PiCId.results.length > 0) {
    ids = entpl.PiCId.results;
    console.log("PiCId exists and has results:", entpl.PiCId);
  }
  // Überprüfen Sie, ob PiC verfügbar ist und Ergebnisse hat
  else if (entpl.PiC && Array.isArray(entpl.PiC.results) && entpl.PiC.results.length > 0) {
    ids = entpl.PiC.results.map(p => p.Id);
    console.log("PiC exists and has results:", entpl.PiC);
  }
  // Überprüfen Sie, ob der Autor verfügbar ist
  else if (entpl.Author && entpl.Author.Title && entpl.Author.Id) {
    console.log("Author exists:", entpl.Author); // Log the Author
    return [{"Title": entpl.Author.Title, "Id": entpl.Author.Id}];
  }
  else {
    console.log("None of the conditions met");
  }
  

    console.log("IDs derived:", ids); // Log the derived ids

    // Filter colleagues based on the ids
    if (ids.length > 0) {
      let filteredColleagues = colleagues.filter(colleague => ids.includes(colleague.Id));
      console.log("Filtered colleagues:", filteredColleagues); // Log the filtered colleagues
      console.log("About to return:", filteredColleagues);

      return filteredColleagues;
    }
  }
  console.log("Returning empty array"); // Log this before returning an empty array
  return [];
}

,
 getColorClass(state) {
  return state.colorClass;
}
}

const mutations = {
  setEntPl(state, entPl) {
    console.log("Setting entPl in state:", entPl);
    state.entPl = entPl;
  },
  setEntPlLoading: (state, loading) => {
    state.loading = loading
  },
  setFetching(state, status) {
    state.isFetching = status;
  },
  setEntPlOrder: (state, order) => {
    state.order = order
  },
  setEntPlView: (state, view) => {
    state.view = view
  },
  setEntPlQuery: (state, query) => {
    state.query = query
  },
  setColorClass(state, colorClass) {
    state.colorClass = colorClass;
    localStorage.setItem('colorClass', colorClass);
  },
  setProcessedEntPl(state, processedData) {
    state.processedEntPl = processedData;
  },
  addEntPl: async (state, student) => {
    if (!student) {
      console.warn("No student provided to addEntPl");
      return;
    }
    const api = store.getters.api;
    if (api !== "pepApi") {
      const changedStudent = await changeEntPl(student); // Verwenden von await, da changeEntPl asynchron ist
      if (changedStudent) { // Überprüfen, ob changedStudent nicht null ist
        state.entPl.push(changedStudent);
      } else {
        console.warn("changeEntPl returned null or empty");
      }
    } else {
      state.entPl.push(student);
    }
  },
  
  setDatenschutz: (state, payload) => {
    if(payload.id && state.entPl) {
      let plan = state.entPl.find(item => item.Id === payload.id)
      plan.DSchutzE = payload.value
    }
  },
  setPiC: (state, payload) => {
    let g = store.state.config.groups.userColleaguesFull;
    if(payload.itemId && state.entPl) {
      let plan = state.entPl.find(item => item.Id === payload.itemId)
      plan.PiCId.results  =  payload.PiCId
      //
      let PiCfull =  g.filter((i) => plan.PiCId.results.includes(i.Id));
      let PiCmapped = PiCfull.map(t => t.Title)
      plan.Verantwortlich = PiCmapped.toString();
      let lockstatus = plan.PiCId.results.includes(store.state.config.user.userId)

      plan.unlocked = lockstatus;
    }
    //store.dispatch("setEntPl")
  },
  deleteUser: (state, id) => {
    if(id) state.entPl = state.entPl.filter(item => item.Id !== id)
  },
  updateEntplfromSVz: (state, payload) => {
    if(payload.svzId && state.entPl) {
      let plan = state.entPl.find(item => item.RefSId === payload.svzId)
      plan.RefS.FirstName = payload.FirstName
      plan.RefS.Title = payload.Title
      }
    },
    setEntplPiC: (state, payload) => {
      let plan = state.entPl.find(item => item.Id === payload.id);
      console.log("setEntplPiC before: ", plan);
      if (plan) {
        let metadata = { "__metadata": { "type": "Collection(Edm.Int32)" } };
    
        plan.PiC = { ...metadata, "results": payload.pic };
        plan.PiCId = { ...metadata, "results": payload.pic.map(p => p.Id) };
    
        console.log("setEntplPiC after: ", plan);
      }
    }
  }
    
  function checkAndLoadEntPl(state) {
    // Überprüfen, ob entPl null oder leer ist und ob keine Anfrage läuft
    if((state.entPl === null || state.entPl === "") && !state.isFetching) {
      store.dispatch("loadEntPl");
    }
  }
  




export default {
  state,
  mutations,
  getters,
  actions
}
